import React, { FC, useContext } from "react";
import cx from "classnames";
import PlayerInfo from "./PlayerInfo";
import { PlayerIdContext } from "../Providers/PlayerId";
import { FullGameStateContext } from "../Providers/FullGameState";
import { ScoringStateContext } from "../Providers/ScoringState";
import { getHasPlayerVoted } from "../../utils/fullGameState";
import { ProgressIndicator } from "./ProgressIndicator";
import { motion } from "framer-motion";
import IconLike from "../display/Icon/IconLike";
import Badge from "../display/Badge";

type PlayerListEndGameProps = {
  className?: string;
};

const PlayerListEndGame: FC<PlayerListEndGameProps> = ({ className }) => {
  const { fullGameState } = useContext(FullGameStateContext);
  const { playerId } = useContext(PlayerIdContext);
  const { currentTurnIndex, indexOfPlayerShowing } = useContext(
    ScoringStateContext
  );

  if (
    fullGameState == null ||
    fullGameState.playerIds == null ||
    fullGameState.playerInfoMap == null
  ) {
    return <div className={className} />;
  }
  const {
    playerIds,
    playerPointsMap,
    playerInfoMap,
    playerLikesMap,
  } = fullGameState;

  const players = playerIds
    .map((id) => {
      const sumPoints = playerPointsMap?.[id]?.reduce(
        (sum, pointAward) => sum + (pointAward.isAwardedPoints ? 1 : 0),
        0
      );
      const sumLikes = playerLikesMap?.[id]?.reduce(
        (sum, pointAward) => sum + (pointAward.isAwardedPoints ? 1 : 0),
        0
      );
      return {
        ...playerInfoMap[id],
        sumPoints,
        sumLikes,
        isFinished: getHasPlayerVoted(
          fullGameState,
          indexOfPlayerShowing,
          currentTurnIndex,
          id
        ),
      };
    })
    .sort((p1, p2) => (p2.sumPoints || 0) - (p1.sumPoints || 0));

  return (
    <div className={cx(className, "border-solid border-gray-900 border-2")}>
      {players.map(({ sumPoints, isFinished, sumLikes, ...player }, index) => {
        return (
          <motion.li
            positionTransition
            key={player.id}
            className={cx(
              { "border-gray-900 border-t-2 border-dashed": index > 0 },
              "flex p-2 items-center truncate"
            )}
          >
            <ProgressIndicator isWorking={isFinished === false} />
            <PlayerInfo className="mr-4" playerId={playerId} player={player} />
            <div className="ml-auto flex justify-end items-center">
              {sumLikes != null && sumLikes !== 0 && (
                <div className="relative flex-shrink-0 mr-4 -mb-1">
                  <Badge
                    className="absolute"
                    style={{
                      top: "-0.6rem",
                      right: "-0.6rem",
                    }}
                    kind="success"
                    size="m"
                  >
                    {sumLikes}
                  </Badge>
                  <IconLike className="ml-auto" size="s" />
                </div>
              )}
              <div className="bg-gray-200 text-black rounded-full p-2 relative">
                {sumPoints || 0}
                {Array.from({ length: sumPoints || 0 }, (_v, i) => (
                  <motion.div
                    key={i}
                    className="absolute text-green-500 text-xl"
                    initial={{ top: 5, left: 5 }}
                    transition={{
                      duration: 2,
                    }}
                    animate={{
                      transform: "translate(25px, -25px)",
                      opacity: 0,
                    }}
                  >
                    +1
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.li>
        );
      })}
    </div>
  );
};

export default PlayerListEndGame;

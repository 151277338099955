import React, { createContext, FC, useMemo, useContext } from "react";
import { GameSession } from "../../gameSession/GameSession";
import { ClientGameSession } from "../../../shared/types";
import { GameStateContext } from "./GameState";

class SafeGameSession {
  private session?: GameSession = undefined;
  onConnect?: (response: ClientGameSession) => any;
  gameId?: string;

  get game() {
    if (this.gameId == null) {
      throw new Error("there is no game id");
    }

    if (this.session == null) {
      this.session = new GameSession(this.gameId, this.onConnect);
    }

    return this.session;
  }
}

const safeSession = new SafeGameSession();

export const GameSessionContext = createContext<{
  session: SafeGameSession;
}>({
  session: safeSession,
});

export const GameSessionProvider: FC = ({ children }) => {
  const { setGameState } = useContext(GameStateContext);
  // TODO: this feels like a hack :(
  if (safeSession.onConnect == null) {
    safeSession.onConnect = setGameState;
  }
  const providerValue = useMemo(
    () => ({
      session: safeSession,
    }),
    []
  );

  return (
    <GameSessionContext.Provider value={providerValue}>
      {children}
    </GameSessionContext.Provider>
  );
};

export default GameSessionProvider;

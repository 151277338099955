import { GetDrawingResponse } from "../../shared/types";
import { makeId } from "../../shared/id";

const URL =
  "https://7igqon85j5.execute-api.us-east-2.amazonaws.com/prod/drawings";

class DrawingsAPI {
  async getDrawing(drawingId: string) {
    const response = await fetch(`${URL}/${drawingId}`, {
      method: "GET",
    });
    // TODO: make this more robust
    const getDrawing = await response.json();

    return (getDrawing as GetDrawingResponse).data;
  }

  async saveDrawing(dataUrl: string) {
    const drawingId = makeId();
    const response = await fetch(`${URL}/${drawingId}`, {
      method: "POST",
      body: JSON.stringify({
        data: {
          dataUrl,
        },
      }),
    });

    // TODO: make this more robust
    if (response.status > 399) {
      const result = await response.json();
      throw new Error(result.toString());
    }

    return {
      drawingId,
    };
  }
}

export default DrawingsAPI;

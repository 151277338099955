import React, { FC, useEffect, useState } from "react";
import cx from "classnames";
import DrawingsAPI from "../../drawings/DrawingsAPI";

type DrawingImageProps = {
  className?: string;
  drawingId: string;
};

const drawingsAPI = new DrawingsAPI();

const DrawingImage: FC<DrawingImageProps> = ({ drawingId, className }) => {
  const [imgUrl, setImgUrl] = useState<string>();

  useEffect(() => {
    const getImageUrl = async () => {
      const { dataUrl } = await drawingsAPI.getDrawing(drawingId);

      setImgUrl(dataUrl);
    };

    getImageUrl();
  }, [drawingId]);

  if (!imgUrl) {
    return (
      <div
        className={cx(
          "text-center flex justify-center items-center",
          className
        )}
        style={{ maxWidth: "100%", width: 490, height: 220 }}
      >
        <div>loading...</div>
      </div>
    );
  }

  return <img className={className} src={imgUrl}></img>;
};

export default DrawingImage;

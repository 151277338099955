import React, { FC, ComponentPropsWithoutRef } from "react";
import cx from "classnames";

interface HeadingProps extends ComponentPropsWithoutRef<"h1"> {
  level: "1" | "2" | "3" | "4" | "5" | "6";
  size: "s" | "m" | "l" | "xl";
  className?: string;
}

const Heading: FC<HeadingProps> = ({ level, size, className, ...props }) => {
  const Tag = `h${level}` as "h1";

  return (
    <Tag
      {...props}
      className={cx(
        {
          "text-base": size === "s",
          "text-xl": size === "m",
          "text-2xl": size === "l",
          "text-4xl": size === "xl",
        },
        className
      )}
    ></Tag>
  );
};

export default Heading;

import {
  GameSession as GameSessionType,
  GameSessionAction,
  GameSessionActionData,
  AddPlayerData,
  ErrorBody,
  StartGameData,
  SubmitDrawingData,
  ChooseWordData,
  ClientGameSession,
  WSResponse,
  GoToNextTurnData,
  SubmitGuessData,
  StartScoringData,
  ScoreEntriesData,
  ChangeNameData,
  GoToNextRoundData,
  StartNewGameData,
  LikeEntryData,
} from "../../shared/types";
import { playerInfo } from "../playerInfo/storage";

const GAME_SESSION_URL =
  "wss://hauu136c68.execute-api.us-east-2.amazonaws.com/prod";

const makeWSMessage = (
  action: GameSessionAction,
  data: GameSessionActionData[typeof action]
) =>
  JSON.stringify({
    action,
    data,
  });

type ActionSubscriber<T> = (data: T) => void;
// ClientGameSession | GameSessionType | string
type SubscriberMap = {
  [GameSessionAction.AddPlayer]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.ChangeName]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.ChooseWord]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.Connect]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.GoToNextRound]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.GoToNextTurn]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.Reconnect]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.ScoreEntries]: ActionSubscriber<GameSessionType>[];
  [GameSessionAction.LikeEntry]: ActionSubscriber<GameSessionType>[];
  [GameSessionAction.StartGame]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.StartNewGame]: ActionSubscriber<string>[];
  [GameSessionAction.StartScoring]: ActionSubscriber<GameSessionType>[];
  [GameSessionAction.SubmitDrawing]: ActionSubscriber<ClientGameSession>[];
  [GameSessionAction.SubmitGuess]: ActionSubscriber<ClientGameSession>[];
  // [action in GameSessionAction]: ActionSubscriber[];
};

type SuccessMessage = WSResponse<GameSessionAction, ClientGameSession>;

const getIsSuccessMessage = (data: unknown): data is SuccessMessage =>
  Object.values(GameSessionAction).includes((data as SuccessMessage)?.action) &&
  // if it's a connect action, it won't have a body
  ((data as SuccessMessage)?.action === GameSessionAction.Connect ||
    (data as SuccessMessage)?.data != null);

const getIsErrorMessage = (data: unknown): data is ErrorBody =>
  (data as ErrorBody)?.error != null;

type OnConnectFn = (response: ClientGameSession) => any;

export class GameSession {
  private websocket: WebSocket;
  private subscriberMap: SubscriberMap;
  private isOpen: boolean;
  private gameId: string;
  private pendingMessages: string[];
  private onConnect?: OnConnectFn;

  constructor(gameId: string, onConnect?: OnConnectFn) {
    this.gameId = gameId;
    this.pendingMessages = [];
    this.isOpen = false;
    this.onConnect = onConnect;
    this.websocket = new WebSocket(
      `${GAME_SESSION_URL}?playerId=${playerInfo.playerId}&gameId=${this.gameId}`
    );

    this.websocket.onmessage = this.onMessage;
    // this.websocket.onclose = this.onClose;
    // this.websocket.onerror = this.onError;
    this.websocket.onopen = this.onOpen;
    this.subscriberMap = Object.values(GameSessionAction).reduce(
      (finalMap, action) => {
        finalMap[action] = [];

        return finalMap;
      },
      {} as SubscriberMap
    );
  }

  private onOpen = (_event: Event) => {
    this.isOpen = true;
    // try to reconnect if they are already connected and refreshed
    this.websocket.send(
      makeWSMessage(GameSessionAction.Reconnect, {
        gameId: this.gameId,
        playerId: playerInfo.playerId,
      })
    );

    this.pendingMessages.forEach((message) => {
      this.websocket.send(message);
    });
  };

  // private onError(event: Event) {}

  // private onClose(event: CloseEvent) {}

  private onMessage = (messageEvent: MessageEvent) => {
    const data = JSON.parse(messageEvent.data) as unknown;
    if (getIsSuccessMessage(data)) {
      // reconnect is fired when the user connects and is
      if (
        data.action === GameSessionAction.Reconnect ||
        data.action === GameSessionAction.Connect
      ) {
        // if it's a connect action, it could be undefined, so we need to set that equal to this empty object
        this.onConnect?.(data.data ?? {});
      }
      const subscribers = this.subscriberMap[data.action];
      subscribers.forEach((subscriber: any) => {
        subscriber(data.data);
      });
    } else if (getIsErrorMessage(data)) {
      console.error(data.error.reason);
    } else {
      console.error("unknown error", data);
    }
  };

  private sendMessage = (
    action: GameSessionAction,
    data: GameSessionActionData[typeof action]
  ) => {
    const message = makeWSMessage(action, data);
    if (this.isOpen) {
      this.websocket.send(message);
    } else {
      this.pendingMessages.push(message);
    }
  };

  addPlayer(data: AddPlayerData) {
    this.sendMessage(GameSessionAction.AddPlayer, data);
  }

  changeName(data: ChangeNameData) {
    this.sendMessage(GameSessionAction.ChangeName, data);
  }

  startGame(data: StartGameData) {
    this.sendMessage(GameSessionAction.StartGame, data);
  }

  startNewGame(data: StartNewGameData) {
    this.sendMessage(GameSessionAction.StartNewGame, data);
  }

  submitDrawing(data: SubmitDrawingData) {
    this.sendMessage(GameSessionAction.SubmitDrawing, data);
  }

  submitGuess(data: SubmitGuessData) {
    this.sendMessage(GameSessionAction.SubmitGuess, data);
  }

  chooseWord(data: ChooseWordData) {
    this.sendMessage(GameSessionAction.ChooseWord, data);
  }

  goToNextTurn(data: GoToNextTurnData) {
    this.sendMessage(GameSessionAction.GoToNextTurn, data);
  }

  goToNextRound(data: GoToNextRoundData) {
    this.sendMessage(GameSessionAction.GoToNextRound, data);
  }

  startScoring(data: StartScoringData) {
    this.sendMessage(GameSessionAction.StartScoring, data);
  }

  scoreEntries(data: ScoreEntriesData) {
    this.sendMessage(GameSessionAction.ScoreEntries, data);
  }

  likeEntry(data: LikeEntryData) {
    this.sendMessage(GameSessionAction.LikeEntry, data);
  }

  subscribe(
    action:
      | GameSessionAction.AddPlayer
      | GameSessionAction.ChangeName
      | GameSessionAction.ChooseWord
      | GameSessionAction.Connect
      | GameSessionAction.GoToNextRound
      | GameSessionAction.GoToNextTurn
      | GameSessionAction.Reconnect
      | GameSessionAction.StartGame
      | GameSessionAction.SubmitDrawing
      | GameSessionAction.SubmitGuess,
    fn: ActionSubscriber<ClientGameSession>
  ): () => void;
  subscribe(
    action: GameSessionAction.StartNewGame,
    fn: ActionSubscriber<string>
  ): () => void;
  subscribe(
    action:
      | GameSessionAction.ScoreEntries
      | GameSessionAction.LikeEntry
      | GameSessionAction.StartScoring,
    fn: ActionSubscriber<GameSessionType>
  ): () => void;
  subscribe(action: GameSessionAction, fn: ActionSubscriber<any>) {
    this.subscriberMap[action].push(fn);

    const unsubscribe = () => {
      const actionSubscribers = this.subscriberMap[action];
      const index = actionSubscribers.findIndex(
        // @ts-ignore
        (subscriber: typeof fn) => subscriber === fn
      );
      this.subscriberMap[action] = actionSubscribers
        .slice(0, index)
        // @ts-ignore
        .concat(actionSubscribers.slice(index + 1));
    };

    return unsubscribe;
  }
}

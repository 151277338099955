import React, {
  FC,
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useParams, Redirect } from "react-router-dom";
import WordsAPI from "../../../words/WordsAPI";
import SelectWord from "../../Words/SelectWord";
import { GameSessionContext } from "../../Providers/GameSession";
import { PlayerIdContext } from "../../Providers/PlayerId";
import { GameSessionAction, PlayerAction } from "../../../../shared/types";
import { GameStateContext } from "../../Providers/GameState";
import Canvas from "../../Canvas";
import SubmitGuessForm, { SubmitGuessFormData } from "./SubmitGuessForm";
import DrawingsAPI from "../../../drawings/DrawingsAPI";
import DrawingImage from "../../DrawingImage";
import { getDidAllPlayersChooseWord } from "../../../utils/gameState";
import Heading from "../../display/Heading";

const wordsAPI = new WordsAPI();
const drawingsAPI = new DrawingsAPI();

type GamePageParams = {
  gameId: string;
};

const GamePage: FC = () => {
  const sessionContext = useContext(GameSessionContext);
  const { gameState, setGameState } = useContext(GameStateContext);
  const { playerId } = useContext(PlayerIdContext);
  const { gameId } = useParams<GamePageParams>();

  const [words, setWords] = useState<string[]>();
  const numPlayers = gameState.players?.length ?? Infinity;
  const maxTurns = numPlayers % 2 === 0 ? numPlayers : numPlayers - 1;
  const isLastRound = (gameState.currentTurnIndex ?? 0) >= maxTurns - 1;
  const didAllPlayersCompleteTurn = useMemo(() => {
    return (
      gameState.personalRoundInfo?.isCurrentActionComplete &&
      gameState.playersRoundInfo?.every((info) => info.isCurrentActionComplete)
    );
  }, [gameState.personalRoundInfo, gameState.playersRoundInfo]);

  useEffect(() => {
    sessionContext.session.gameId = gameId;
    const unsubscribeToChooseWord = sessionContext.session.game.subscribe(
      GameSessionAction.ChooseWord,
      (newState) => {
        setGameState(newState);
      }
    );
    const unsubscribeToSubmitDrawing = sessionContext.session.game.subscribe(
      GameSessionAction.SubmitDrawing,
      (newState) => {
        setGameState(newState);
      }
    );
    const unsubscribeToSubmitGuess = sessionContext.session.game.subscribe(
      GameSessionAction.SubmitGuess,
      (newState) => {
        setGameState(newState);
      }
    );
    const unsubscribeToGoToNextTurn = sessionContext.session.game.subscribe(
      GameSessionAction.GoToNextTurn,
      (newState) => {
        setGameState(newState);
      }
    );

    return () => {
      unsubscribeToChooseWord();
      unsubscribeToSubmitDrawing();
      unsubscribeToSubmitGuess();
      unsubscribeToGoToNextTurn();
    };
  }, [
    gameId,
    sessionContext.session,
    sessionContext.session.gameId,
    setGameState,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const words = await wordsAPI.getWords();
        setWords(words);
      } catch (e) {
        console.error(e);
      }
    };

    if (!gameState.personalRoundInfo?.didChooseWord) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (didAllPlayersCompleteTurn) {
      // go to next turn, make sure this is idempotent
      sessionContext.session.game.goToNextTurn({
        currentTurnIndex: gameState.currentTurnIndex ?? 0,
        gameSessionId: gameId,
      });
    }
  }, [
    didAllPlayersCompleteTurn,
    gameId,
    gameState.currentTurnIndex,
    sessionContext.session,
  ]);

  const selectWord = useCallback(
    (word) => {
      sessionContext.session.game.chooseWord({
        playerId,
        word,
        gameSessionId: gameId,
      });
    },
    [playerId, gameId, sessionContext.session]
  );

  const handleSubmitDrawing = useCallback(
    async (dataUrl) => {
      const { drawingId } = await drawingsAPI.saveDrawing(dataUrl);
      sessionContext.session.game.submitDrawing({
        gameId,
        playerId,
        drawingId,
        currentRoundIndex: gameState.currentRoundIndex ?? 0,
        currentTurnIndex: gameState.currentTurnIndex ?? 0,
      });
    },
    [
      gameId,
      gameState.currentRoundIndex,
      gameState.currentTurnIndex,
      playerId,
      sessionContext.session,
    ]
  );
  const submitGuess = useCallback(
    async (formData: SubmitGuessFormData) => {
      await sessionContext.session.game.submitGuess({
        gameId,
        playerId,
        guess: formData.guess,
        currentRoundIndex: gameState.currentRoundIndex ?? 0,
        currentTurnIndex: gameState.currentTurnIndex ?? 0,
      });
    },
    [
      gameId,
      gameState.currentRoundIndex,
      gameState.currentTurnIndex,
      playerId,
      sessionContext.session,
    ]
  );

  if (gameState.id == null) {
    return <div>loading...</div>;
  }

  if (isLastRound && didAllPlayersCompleteTurn) {
    return (
      <Redirect
        to={`/games/${gameId}/scoring/${gameState?.currentRoundIndex}`}
        push
      />
    );
  }

  let content;

  if (getDidAllPlayersChooseWord(gameState)) {
    content = (
      <div className="flex flex-col items-center">
        {gameState.personalRoundInfo?.isCurrentActionComplete ? (
          <div>waiting for other players to finish...</div>
        ) : gameState.currentAction === PlayerAction.Drawing ? (
          <>
            <Heading level="1" size="s" className="mx-auto">
              draw this word
            </Heading>
            <Heading size="m" level="2" className="font-bold mb-4">
              {gameState.personalRoundInfo?.currentDrawingOrWord}
            </Heading>
            <Canvas onSubmitDrawing={handleSubmitDrawing} />
          </>
        ) : (
          <>
            <Heading level="1" size="s" className="mx-auto mb-4">
              guess this word
            </Heading>
            <DrawingImage
              drawingId={
                gameState.personalRoundInfo?.currentDrawingOrWord || "404"
              }
            />
            <SubmitGuessForm onSubmit={submitGuess} />
          </>
        )}
      </div>
    );
  } else if (gameState.personalRoundInfo?.didChooseWord) {
    content = (
      <p>
        You chose: {gameState.personalRoundInfo?.chosenWord}. Waiting for other
        players...
      </p>
    );
  } else if (words == null) {
    content = <div>loading words...</div>;
  } else {
    content = <SelectWord selectWord={selectWord} words={words} />;
  }

  return content;
};

export default GamePage;

import React from "react";
import { SimpleIconComponent } from "./types";
import { withBaseIcon } from "./BaseIcon";

const IconPainting: SimpleIconComponent = ({ className, style }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#be694f"
        d="M415.308 503.309l-63.27-393.115c-.658-4.089-4.502-6.879-8.594-6.212a7.498 7.498 0 00-6.212 8.594l53.896 334.87H120.873l53.896-334.87a7.498 7.498 0 00-6.212-8.594c-4.089-.663-7.937 2.123-8.595 6.212l-63.27 393.115c-.77 4.508 2.849 8.73 7.413 8.691a7.5 7.5 0 007.394-6.308l6.96-43.247h275.083l6.96 43.247a7.499 7.499 0 007.394 6.308c4.564.039 8.182-4.184 7.412-8.691z"
      ></path>
      <path
        fill="#be694f"
        d="M256 190.965a7.5 7.5 0 01-7.499-7.499V7.499a7.5 7.5 0 0114.998 0v175.967a7.498 7.498 0 01-7.499 7.499z"
      ></path>
      <path
        fill="#dae7ef"
        d="M434.706 373.556H77.294c-7.732 0-14-6.268-14-14V150.975c0-7.732 6.268-14 14-14h357.413c7.732 0 14 6.268 14 14v208.581c-.001 7.732-6.269 14-14.001 14z"
      ></path>
      <path
        fill="#c1d5e4"
        d="M434.709 136.975h-30.884v177.702c0 7.731-6.267 13.997-13.997 13.997H63.294v30.884c0 7.73 6.267 13.997 13.997 13.997h357.418c7.73 0 13.997-6.267 13.997-13.997V150.972c0-7.731-6.266-13.997-13.997-13.997z"
      ></path>
      <path
        fill="#be694f"
        d="M294.608 111.386h-77.217V65.397c0-7.732 6.268-14 14-14h49.217c7.732 0 14 6.268 14 14z"
      ></path>
      <path
        fill="#aa593d"
        d="M280.611 51.397h-17.403c7.731 0 13.997 6.267 13.997 13.997v45.991h17.403V65.394c0-7.73-6.267-13.997-13.997-13.997z"
      ></path>
      <path
        fill="#c67d66"
        d="M303.426 136.975h-94.851a6 6 0 01-6-6V108.95a6 6 0 016-6h94.851a6 6 0 016 6v22.025a6 6 0 01-6 6zM424.404 407.581H87.596a6 6 0 01-6-6v-22.025a6 6 0 016-6h336.808a6 6 0 016 6v22.025a6 6 0 01-6 6z"
      ></path>
      <path
        fill="#be694f"
        d="M303.427 102.95h-8.045v13.982a6 6 0 01-5.999 5.999h-86.808v8.045a6 6 0 005.999 5.999h94.853a6 6 0 005.999-5.999v-22.027a5.999 5.999 0 00-5.999-5.999zM424.405 373.556h-8.045v13.982a6 6 0 01-5.999 5.999H81.596v8.045a6 6 0 005.999 5.999h336.81a6 6 0 005.999-5.999v-22.027a5.999 5.999 0 00-5.999-5.999z"
      ></path>
      <circle cx="256" cy="86.859" r="11.998" fill="#2b4d66"></circle>
      <g>
        <path
          fill="#c67d66"
          d="M274.897 324.782H256c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h18.897a9.714 9.714 0 009.161-6.502l7.736-22.132a7.5 7.5 0 019.555-4.605 7.5 7.5 0 014.605 9.555l-7.736 22.132c-3.46 9.9-12.832 16.552-23.321 16.552z"
        ></path>
      </g>
      <path
        fill="#c67d66"
        d="M266.684 287.225V270.58h-22.196v16.645c0 5.381-.281 10.758-.842 16.109l-4.417 42.12a3 3 0 002.984 3.313h26.747a3 3 0 002.984-3.313l-4.417-42.12a154.222 154.222 0 01-.843-16.109z"
      ></path>
      <path
        fill="#78d090"
        d="M320.464 215.328c.748-3.726.958-7.647.531-11.684-1.962-18.517-17.284-33.112-35.873-34.196-9.809-.572-18.877 2.542-25.953 8.084a5.825 5.825 0 01-7.165.001c-7.086-5.552-16.172-8.667-25.999-8.083-18.855 1.121-34.287 16.146-35.903 34.965a38.56 38.56 0 00.841 12.013c.644 2.782-.855 5.626-3.499 6.705-14.722 6.009-24.878 20.891-23.817 38.003 1.217 19.631 17.459 35.329 37.118 35.928a38.226 38.226 0 0024.501-7.869c2.121-1.623 5.055-1.501 7.183.112a38.202 38.202 0 0023.156 7.775 38.205 38.205 0 0024.23-8.623 5.826 5.826 0 017.17-.129 38.218 38.218 0 0024.324 7.692c19.691-.629 35.924-16.405 37.071-36.072 1.006-17.245-9.38-32.197-24.331-38.052a5.838 5.838 0 01-3.585-6.57z"
      ></path>
      <path
        fill="#be694f"
        d="M242.213 348.767h26.747a3 3 0 002.984-3.313l-1.76-16.781h-29.195l-1.76 16.781a3 3 0 002.984 3.313z"
      ></path>
    </svg>
  );
};

export default withBaseIcon()(IconPainting);

import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { isString, mapValues } from "lodash/fp";
import {
  GameSessionAction,
  Drawing,
  Guess,
  PointAward,
} from "../../../../shared/types";
import useAsyncState from "../../../utils/hooks/useAsyncState";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { GameSessionContext } from "../../Providers/GameSession";
import DrawingImage from "../../DrawingImage";
import { PlayerIdContext } from "../../Providers/PlayerId";
import Button from "../../display/Button";
import { FullGameStateContext } from "../../Providers/FullGameState";
import { ScoringStateContext } from "../../Providers/ScoringState";
import {
  getHaveAllPlayersVoted,
  getIsLastRound,
} from "../../../utils/fullGameState";
import IconTrophy from "../../display/Icon/IconTrophy";
import IconSilverMedal from "../../display/Icon/IconSilverMedal";
import IconBronzeMedal from "../../display/Icon/IconBronzeMedal";
import { GameStateContext } from "../../Providers/GameState";
import IconLike from "../../display/Icon/IconLike";
import Badge from "../../display/Badge";

type ScoringPageParams = {
  gameId: string;
  roundIndex: string;
};

const getIsDrawing = (entry: any): entry is Drawing => isString(entry.id);
const getIsGuess = (entry: any): entry is Guess => isString(entry.word);

const ScoringPage = () => {
  const sessionContext = useContext(GameSessionContext);
  const {
    currentTurnIndex,
    indexOfPlayerShowing,
    incrementPlayerIndex,
    incrementTurnIndex,
    reset,
  } = useContext(ScoringStateContext);
  const history = useHistory();
  const { playerId } = useContext(PlayerIdContext);
  const { fullGameState, setFullGameState, resetFullGameState } = useContext(
    FullGameStateContext
  );
  const { gameState, resetGameState } = useContext(GameStateContext);
  const { gameId, roundIndex } = useParams<ScoringPageParams>();
  const currentRoundIndex = Number(roundIndex);
  const [
    startScoringAsync,
    requestStartScoring,
    successStartScoring,
  ] = useAsyncState();
  const [
    scoreEntriesAsync,
    requestScoreEntries,
    successScoreEntries,
    ,
    resetScoreEntries,
  ] = useAsyncState();
  const [didLike, setDidLike] = useState(false);

  useEffect(() => {
    return () => {
      reset();
      resetFullGameState();
    };
    // we REALLY only want this to fire 1 time, on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sessionContext.session.gameId = gameId;
    const unsubscribeToStartScoring = sessionContext.session.game.subscribe(
      GameSessionAction.StartScoring,
      (state) => {
        if (startScoringAsync.isLoading) {
          successStartScoring();
        }

        setFullGameState(state);
      }
    );

    const unsubscribeToScoreEntries = sessionContext.session.game.subscribe(
      GameSessionAction.ScoreEntries,
      (state) => {
        if (scoreEntriesAsync.isLoading) {
          successScoreEntries();
        }

        setFullGameState(state);
      }
    );

    const unsubscribeToLikeEntry = sessionContext.session.game.subscribe(
      GameSessionAction.LikeEntry,
      (state) => {
        setFullGameState(state);
      }
    );

    const unsubscribeToStartNewGame = sessionContext.session.game.subscribe(
      GameSessionAction.StartNewGame,
      (newGameId) => {
        resetFullGameState();
        resetGameState();
        history.push(`/lobbies/${newGameId}`);
      }
    );

    return () => {
      unsubscribeToStartScoring();
      unsubscribeToScoreEntries();
      unsubscribeToLikeEntry();
      unsubscribeToStartNewGame();
    };
  }, [
    gameId,
    sessionContext.session,
    successStartScoring,
    successScoreEntries,
    startScoringAsync.isLoading,
    scoreEntriesAsync.isLoading,
    setFullGameState,
    history,
    resetFullGameState,
    resetGameState,
  ]);
  useEffect(() => {
    if (
      !fullGameState &&
      !startScoringAsync.isLoading &&
      !startScoringAsync.isLoaded
    ) {
      requestStartScoring();
      // start scoring is idempotent, so it won't break if we do it multiple times
      sessionContext.session.game.startScoring({
        currentRoundIndex,
        gameSessionId: gameId,
      });
    }
  }, [
    fullGameState,
    gameId,
    requestStartScoring,
    currentRoundIndex,
    sessionContext.session,
    startScoringAsync.isLoaded,
    startScoringAsync.isLoading,
  ]);

  useEffect(() => {
    const haveAllPlayersVoted = getHaveAllPlayersVoted(
      fullGameState,
      indexOfPlayerShowing,
      currentTurnIndex
    );
    const isLastRound = getIsLastRound(
      fullGameState,
      indexOfPlayerShowing,
      currentTurnIndex
    );

    if (haveAllPlayersVoted) {
      if (isLastRound) {
        incrementPlayerIndex();
        resetScoreEntries();
        setDidLike(false);
      } else {
        incrementTurnIndex();
        resetScoreEntries();
        setDidLike(false);
      }
    }
  }, [
    currentTurnIndex,
    fullGameState,
    incrementPlayerIndex,
    incrementTurnIndex,
    indexOfPlayerShowing,
    resetScoreEntries,
  ]);

  // we need to make sure the full game state (start scoring) is loaded & on refresh, the "safe" game state is loaded
  if (fullGameState == null || gameState.currentRoundIndex == null) {
    return <div>loading round results...</div>;
  }

  const {
    playerIds,
    playerInfoMap,
    playerRoundsMap,
    playerPointsMap,
    playerLikesMap,
  } = fullGameState;

  if (
    playerRoundsMap == null ||
    playerIds == null ||
    playerInfoMap == null ||
    fullGameState.currentRoundIndex == null
  ) {
    return <div>hmm... something went wrong</div>;
  }

  if (currentRoundIndex !== gameState.currentRoundIndex) {
    return <Redirect to={`/games/${gameId}`} />;
  }

  if (indexOfPlayerShowing >= playerIds.length && playerPointsMap != null) {
    const sumAwards = mapValues((awards: PointAward[]) =>
      awards.reduce(
        (sum, award) => (award.isAwardedPoints ? sum + 1 : sum + 0),
        0
      )
    );
    const pointTotals = sumAwards(playerPointsMap);
    const likeTotals = sumAwards(playerLikesMap);
    const reverseSortedPointTotalsTuples = Object.entries(pointTotals).sort(
      ([, points1], [, points2]) => points1 - points2
    );

    return (
      <div className="flex flex-col items-center mt-8">
        {reverseSortedPointTotalsTuples.map(([id, sum], index) => {
          const placeNum = reverseSortedPointTotalsTuples.length - index;
          const numLikes = likeTotals[id];

          return (
            <div
              key={id}
              className={cx(
                { "mb-4": placeNum === 1, "mb-2": placeNum !== 1 },
                "flex items-center"
              )}
              style={{ order: placeNum }}
            >
              {placeNum === 1 && <IconTrophy className="mr-2" size="l" />}
              {placeNum === 2 && <IconSilverMedal className="mr-2" size="m" />}
              {placeNum === 3 && <IconBronzeMedal className="mr-2" size="s" />}
              <div className="flex flex-col items-center">
                <div
                  className={cx(
                    {
                      "text-xl": placeNum === 1,
                    },
                    " font-bold"
                  )}
                >
                  #{placeNum}: {fullGameState.playerInfoMap?.[id]?.name}
                </div>
                <div>{sum} points</div>
              </div>
              {numLikes != null && numLikes !== 0 && (
                <div className="relative flex-shrink-0 ml-4">
                  <Badge
                    className="absolute"
                    style={{
                      top: "-0.6rem",
                      right: "-0.6rem",
                    }}
                    kind="success"
                    size="m"
                  >
                    {numLikes}
                  </Badge>
                  <IconLike className="ml-auto" size="s" />
                </div>
              )}
            </div>
          );
        })}
        <Button
          className="mt-4 order-last"
          size="m"
          onClick={() => {
            sessionContext.session.game.startNewGame({
              gameSessionId: gameId,
            });
          }}
        >
          Play another round
        </Button>
      </div>
    );
  }

  const playerIdToShow = playerIds[indexOfPlayerShowing];
  const round = playerRoundsMap[playerIdToShow][currentRoundIndex];
  const playerToShow = playerInfoMap[playerIdToShow];
  const currentEntry = round.entries[currentTurnIndex];
  const previousEntry = round.entries[currentTurnIndex - 1];
  const currentOwner = playerInfoMap[currentEntry.ownerId];
  const previousOwner = playerInfoMap[previousEntry?.ownerId];
  const currentOwnerPoints = playerPointsMap?.[currentOwner.id] || [];
  const shouldScoreEntries = getIsGuess(currentEntry) && currentTurnIndex !== 0;
  const canVote =
    !scoreEntriesAsync.isLoading &&
    !scoreEntriesAsync.isLoaded &&
    currentOwnerPoints.filter((pointAward) => pointAward.awardedBy === playerId)
      .length <
      indexOfPlayerShowing + 1 + currentRoundIndex * 2;

  const reversedEntries = round.entries
    .map((entry, index) => ({
      entry,
      index,
    }))
    .slice(0, currentTurnIndex + 1)
    .reverse();
  const scoreEntries = (shouldGetPoints: boolean) => {
    requestScoreEntries();
    sessionContext.session.game.scoreEntries({
      awardingPlayerId: playerId,
      currentRoundIndex,
      currentTurnIndex,
      gameId,
      playerIds: [currentOwner.id, previousOwner.id],
      shouldGetPoints,
    });
  };

  return (
    <div>
      {/* <h2>turn: {currentTurnIndex + 1}</h2> */}
      <div className="flex flex-col items-center text-xl mb-4 border-dashed border-b-2 border-gray-900">
        <span>{playerToShow.name}'s word was</span>
        <b className="text-4xl">{round.word}</b>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col items-center border-dashed border-b-2 py-4 md:order-last md:border-b-0 md:border-l-2 md:px-4 md:py-0">
          {shouldScoreEntries && (
            <>
              <div className="flex flex-col items-center">
                <div>award points to</div>{" "}
                <div className="text-center">
                  <div>
                    guesser:{" "}
                    <b className="whitespace-no-wrap">{currentOwner.name}</b>
                  </div>
                  <div>
                    artist:{" "}
                    <b className="whitespace-no-wrap">{previousOwner.name}</b>
                  </div>
                </div>
              </div>
              {canVote ? (
                <div className="mt-8 flex flex-no-wrap">
                  <Button
                    className="w-20"
                    kind="destructive"
                    onClick={() => scoreEntries(false)}
                  >
                    no
                  </Button>
                  <Button
                    className="ml-4 w-20"
                    kind="primary"
                    onClick={() => scoreEntries(true)}
                  >
                    yes
                  </Button>
                </div>
              ) : (
                <div>you already voted</div>
              )}
            </>
          )}
        </div>
        <div className="py-4 md:py-0 md:px-4">
          {reversedEntries.map(({ entry, index }) => {
            const isDrawing = getIsDrawing(entry);

            const wordToCompareTo =
              index <= 1
                ? round.word
                : (round.entries[index - 2] as Guess).word;

            const didLikeThisDrawing = playerLikesMap?.[entry.ownerId]?.some(
              (likeAward) =>
                (likeAward.awardedBy === playerId &&
                  likeAward.turnIndex === currentTurnIndex) ||
                likeAward.turnIndex === currentTurnIndex - 1
            );
            return (
              <div className="flex flex-col items-center" key={entry.ownerId}>
                <div>
                  <b>{playerInfoMap[entry.ownerId].name}</b>{" "}
                  {isDrawing ? (
                    <>
                      {" "}
                      <span>drew </span>
                      <span className="text-xl font-bold">
                        {wordToCompareTo}
                      </span>
                    </>
                  ) : (
                    "guessed"
                  )}
                </div>
                {getIsDrawing(entry) ? (
                  <div
                    className="relative"
                    style={{ width: "100%", maxWidth: 490, height: 220 }}
                  >
                    <DrawingImage className="shadow-md" drawingId={entry.id} />
                    {!didLike &&
                      !didLikeThisDrawing &&
                      entry.ownerId !== playerId && (
                        <span
                          className="cursor-pointer absolute top-0 right-0 -mt-4 -mr-4"
                          onClick={() => {
                            sessionContext.session.game.likeEntry({
                              awardingPlayerId: playerId,
                              currentRoundIndex,
                              currentTurnIndex,
                              gameId,
                              playerId: entry.ownerId,
                            });
                            setDidLike(true);
                          }}
                        >
                          <IconLike size="m" />
                        </span>
                      )}
                  </div>
                ) : (
                  <div
                    className={cx(
                      {
                        "text-green-500":
                          wordToCompareTo.toLowerCase().trim() ===
                          entry.word.toLowerCase().trim(),
                        "text-red-500":
                          wordToCompareTo.toLowerCase().trim() !==
                          entry.word.toLowerCase().trim(),
                      },
                      "text-4xl font-bold"
                    )}
                  >
                    {entry.word}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScoringPage;

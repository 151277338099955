import React, { FC, useState, useEffect } from "react";
import cx from "classnames";
export const ProgressIndicator: FC<{ isWorking: boolean }> = ({
  isWorking,
}) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const changeDots = () => {
      setCount((currentCount) => {
        return currentCount + 1;
      });
    };
    const interval = window.setInterval(changeDots, 700);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const isEven = count % 2 === 0;

  return (
    <div
      className={cx(
        {
          "bg-green-500": !isWorking,
          "bg-yellow-500": isWorking && isEven,
          "bg-red-500": isWorking && !isEven,
        },
        "flex flex-shrink-0 rounded-full mr-2 transition-colors duration-700"
      )}
      style={{ width: "1rem", height: "1rem" }}
    ></div>
  );
};

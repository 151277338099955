import React, { FC } from "react";
import Button from "../display/Button";

type WidthSelectorProps = {
  incrementWidth: () => void;
  currentWidth: number;
  maxWidth: number;
  color?: string;
};

const WidthSelector: FC<WidthSelectorProps> = ({
  incrementWidth,
  currentWidth,
  maxWidth,
  color,
}) => {
  return (
    <div>
      <label>
        width
        <Button kind="empty" className="block" onClick={incrementWidth}>
          <svg
            viewBox="0 0 100 100"
            width={maxWidth}
            height={maxWidth}
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
          >
            <circle cx="50" cy="50" r={((currentWidth / maxWidth) * 100) / 2} />
          </svg>
        </Button>
      </label>
    </div>
  );
};

export default WidthSelector;

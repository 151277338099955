import React, { useEffect, useCallback, useContext, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GameSessionContext } from "../../Providers/GameSession";
import { GameStateContext } from "../../Providers/GameState";
import { GameSessionAction } from "../../../../shared/types";
import { PlayerIdContext } from "../../Providers/PlayerId";
import Button from "../../display/Button";
import Input from "../../display/Input";
import Heading from "../../display/Heading";
import { copyToClipboard } from "../../../utils/clipboard";

type LobbyPageParams = {
  gameId: string;
};

const LobbyPage = () => {
  const sessionContext = useContext(GameSessionContext);
  const { gameState, setGameState } = useContext(GameStateContext);
  const { playerId, playerName, setPlayerName } = useContext(PlayerIdContext);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: playerName,
    },
  });
  const [{ didCopySucceed, didCopyFail }, setDidCopy] = useState({
    didCopySucceed: false,
    didCopyFail: false,
  });
  const { gameId } = useParams<LobbyPageParams>();

  useEffect(() => {
    let timeout: number;
    if (didCopySucceed || didCopyFail) {
      timeout = window.setTimeout(() => {
        setDidCopy({ didCopySucceed: false, didCopyFail: false });
      }, 2000);
    }

    return () => {
      if (timeout != null) {
        window.clearTimeout(timeout);
      }
    };
  }, [didCopyFail, didCopySucceed]);

  useEffect(() => {
    sessionContext.session.gameId = gameId;
    const unsubscribeAddPlayer = sessionContext.session.game.subscribe(
      GameSessionAction.AddPlayer,
      setGameState
    );
    const unsubscribeChangeName = sessionContext.session.game.subscribe(
      GameSessionAction.ChangeName,
      setGameState
    );

    const unsubscribeStartGame = sessionContext.session.game.subscribe(
      GameSessionAction.StartGame,
      setGameState
    );

    return () => {
      unsubscribeChangeName();
      unsubscribeAddPlayer();
      unsubscribeStartGame();
    };
  }, [sessionContext.session, setGameState, gameId, playerId]);

  const startGame = useCallback(() => {
    sessionContext.session.game.startGame({ gameSessionId: gameId });
  }, [sessionContext.session, gameId]);

  const isInGame = gameState.players?.some((p) => p.id === playerId);

  const canStartGame =
    gameState.players != null && gameState.players.length >= 2;

  return (
    <div className="flex flex-col">
      <Heading level="1" size="l">
        Lobby for Game #{gameId}
      </Heading>
      {gameState.isGameActive && <Redirect to={`/games/${gameId}`} />}
      <div className="flex flex-wrap items-center mb-16">
        <form
          className="mb-8 w-full max-w-sm flex items-end flex-no-wrap"
          onSubmit={handleSubmit((data) => {
            setPlayerName(data.name);
            if (isInGame) {
              sessionContext.session.game.changeName({
                playerId,
                gameSessionId: gameId,
                playerName: data.name,
              });
            } else {
              sessionContext.session.game.addPlayer({
                playerId,
                gameSessionId: gameId,
                playerName: data.name,
              });
            }
          })}
        >
          <Input
            className="w-full flex-shrink"
            label="name:"
            ref={register({ required: true })}
            type="text"
            name="name"
          />
          <Button type="submit" kind="secondary" className="ml-4 flex-shrink-0">
            {isInGame ? "change name" : "join game"}
          </Button>
        </form>

        <Button
          size="l"
          // className="mb-8"
          disabled={!canStartGame}
          onClick={startGame}
        >
          {canStartGame
            ? "Everyone here? Start Game"
            : "You need 2+ players to start"}
        </Button>
      </div>
      <Button
        size="m"
        kind="secondary"
        onClick={() => {
          copyToClipboard(window.location.href);
          setDidCopy({ didCopySucceed: true, didCopyFail: false });
        }}
      >
        {didCopySucceed
          ? "Successfully copied!"
          : didCopyFail
          ? "Failed to copy"
          : "Copy link to share"}
      </Button>
    </div>
  );
};

export default LobbyPage;

import React, { forwardRef } from "react";
import { motion, HTMLMotionProps } from "framer-motion";
import cx from "classnames";

interface ButtonProps extends HTMLMotionProps<"button"> {
  kind?: "primary" | "secondary" | "destructive" | "link" | "empty";
  size?: "m" | "l" | "xl";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, kind = "primary", size, ...props }, ref) => {
    return (
      <motion.button
        className={cx(
          {
            "bg-red-500 hover:bg-red-700 text-white":
              kind === "destructive" && !props.disabled,
            "bg-green-500 hover:bg-green-700 text-white":
              kind === "primary" && !props.disabled,
            "bg-white hover:bg-green-300 border-green-500 hover:border-green-700":
              kind === "secondary" && !props.disabled,
            "bg-white": kind === "empty" && !props.disabled,
            "w-full max-w-xl text-4xl": size === "xl",
            "w-full max-w-md text-2xl": size === "l",
            "w-full max-w-sm text-xl": size === "m",
            "bg-gray-300 bg": props.disabled,
          },
          "p-2 border-solid border-2 rounded-lg border-gray-900 transition-colors duration-300 min-w-4",
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </motion.button>
    );
  }
);

export default Button;

import { useReducer, useCallback } from "react";

type AsyncState = {
  isLoading: boolean;
  isLoaded: boolean;
  error?: Error;
};

enum AsyncType {
  Request = "request",
  Success = "success",
  Error = "error",
  Reset = "reset",
}

type AsyncAction = {
  type: AsyncType;
  payload?: Error;
};

const initialState: AsyncState = {
  isLoading: false,
  isLoaded: false,
  error: undefined,
};

const asyncReducer = (state: AsyncState, action: AsyncAction) => {
  switch (action.type) {
    case AsyncType.Request:
      return { isLoading: true, isLoaded: false, error: undefined };
    case AsyncType.Success:
      return { isLoading: false, isLoaded: true, error: undefined };
    case AsyncType.Error:
      return { isLoading: false, isLoaded: false, error: action.payload };
    case AsyncType.Reset:
      return { isLoading: false, isLoaded: false, error: action.payload };
    default:
      return state;
  }
};

const useAsyncState = (): [
  AsyncState,
  () => void,
  () => void,
  (error: Error) => void,
  () => void
] => {
  const [state, dispatch] = useReducer(asyncReducer, initialState);
  const request = useCallback(() => {
    dispatch({ type: AsyncType.Request });
  }, []);
  const success = useCallback(() => {
    dispatch({ type: AsyncType.Success });
  }, []);
  const error = useCallback((error: Error) => {
    dispatch({ type: AsyncType.Error, payload: error });
  }, []);
  const reset = useCallback(() => {
    dispatch({ type: AsyncType.Reset });
  }, []);

  return [state, request, success, error, reset];
};

export default useAsyncState;

import React from "react";
import { SimpleIconComponent } from "./types";
import { withBaseIcon } from "./BaseIcon";

const IconTelephoneGame: SimpleIconComponent = ({ className, style }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 425.178 425.178"
      version="1.1"
      viewBox="0 0 425.178 425.178"
      xmlSpace="preserve"
    >
      <path
        fill="#29ABE2"
        d="M216.673 224.319c0 44.793-29.392 82.726-69.944 95.549-9.541 3.019-19.703 4.649-30.254 4.649-23.995 0-46.023-8.447-63.275-22.522l-42.72 8.332 16.8-40.321c-7.027-13.707-10.993-29.234-10.993-45.687 0-45.55 30.401-83.998 72.017-96.169 7.742-2.273 15.874-3.619 24.269-3.945a97.326 97.326 0 013.903-.073c51.209 0 93.435 38.396 99.452 87.964.493 4.007.745 8.089.745 12.223"
      ></path>
      <path
        fill="#FFF"
        d="M98.891 246.705c0-5.778.943-10.969 2.831-15.569 1.885-4.6 5.777-8.609 11.676-12.031 1.769-1.061 3.95-2.269 6.546-3.627a53.215 53.215 0 007.607-4.865 27.883 27.883 0 006.281-6.634c1.708-2.535 2.565-5.454 2.565-8.758 0-2.477-.473-4.658-1.415-6.546-.945-1.885-2.184-3.478-3.715-4.776-1.535-1.297-3.334-2.27-5.397-2.92a20.892 20.892 0 00-6.28-.973c-2.596 0-4.955.442-7.078 1.326a24.169 24.169 0 00-5.75 3.362 28.272 28.272 0 00-4.6 4.6c-1.357 1.711-2.507 3.334-3.45 4.865L83.32 183.544c1.531-3.655 3.567-6.9 6.104-9.73a37.372 37.372 0 018.668-7.165c3.243-1.946 6.751-3.419 10.527-4.422a45.345 45.345 0 0111.676-1.504c4.481 0 8.904.65 13.27 1.946 4.362 1.299 8.284 3.362 11.765 6.192 3.477 2.831 6.308 6.458 8.492 10.88 2.181 4.423 3.273 9.703 3.273 15.834 0 3.776-.442 7.107-1.326 9.995-.885 2.892-2.155 5.515-3.804 7.873a33.19 33.19 0 01-5.838 6.369c-2.241 1.888-4.777 3.715-7.607 5.484a215.608 215.608 0 01-7.077 4.069c-2.361 1.3-4.512 2.742-6.458 4.335-1.946 1.592-3.538 3.422-4.777 5.484-1.238 2.065-1.857 4.573-1.857 7.52l-19.46.001m.355 41.221v-24.414h19.637v24.414H99.246"
      ></path>
      <path
        fill="#D6B71D"
        d="M142.697 380.969L153.596 403.981 242.906 340.714 221.736 324.942z"
      ></path>
      <path
        fill="#D6B71D"
        d="M370.487 153.178L314.461 232.21 330.232 253.387 393.5 164.077z"
      ></path>
      <path
        fill="#F9DE58"
        d="M153.596 403.981l51.478 21.197a328.096 328.096 0 00129.815-79.808 328.134 328.134 0 0079.808-129.815L393.5 164.077l-63.268 89.31 21.055 6.553a270.384 270.384 0 01-44.021 57.808 270.787 270.787 0 01-57.808 44.021l-6.553-21.056-89.309 63.268z"
      ></path>
      <path
        fill="#3098C4"
        d="M116.475 124.131c-1.305 0-2.609.021-3.903.073a99.57 99.57 0 0012.015 36.719c3.021.283 6.014.865 8.981 1.746 4.362 1.299 8.284 3.362 11.765 6.192 3.477 2.831 6.308 6.458 8.492 10.88 2.181 4.423 3.273 9.703 3.273 15.834v.205a99.756 99.756 0 0024.683 11.738 100.12 100.12 0 0030.254 4.65c1.305 0 2.599-.021 3.893-.074-6.018-49.567-48.244-87.963-99.453-87.963"
      ></path>
      <path
        fill="#DBDBDB"
        d="M124.586 160.923a100.703 100.703 0 0032.51 34.859v-.205c0-6.132-1.092-11.411-3.273-15.834-2.184-4.423-5.014-8.05-8.492-10.88-3.48-2.831-7.403-4.893-11.765-6.192a46.622 46.622 0 00-8.98-1.748"
      ></path>
      <path
        fill="#FC7A51"
        d="M127.45 100.188c0 44.792 29.392 82.726 69.944 95.549a100.115 100.115 0 0030.254 4.65c23.995 0 46.023-8.447 63.275-22.522l42.72 8.331-16.8-40.322c7.027-13.707 10.993-29.234 10.993-45.686 0-45.55-30.401-83.999-72.017-96.17C246.88 1.399 237.432 0 227.649 0 172.307 0 127.45 44.855 127.45 100.188z"
      ></path>
      <path
        fill="#FFF"
        d="M191.766 91.913c-12.864 0-23.33-10.466-23.33-23.33 0-12.865 10.466-23.33 23.33-23.33s23.331 10.466 23.331 23.33c.001 12.864-10.467 23.33-23.331 23.33zm0-36.731c-7.389 0-13.4 6.012-13.4 13.401s6.011 13.401 13.4 13.401 13.401-6.011 13.401-13.401-6.011-13.401-13.401-13.401zM263.52 155.125c-12.864 0-23.331-10.466-23.331-23.33 0-12.865 10.466-23.33 23.331-23.33s23.331 10.466 23.331 23.33-10.466 23.33-23.331 23.33zm0-36.731c-7.389 0-13.401 6.012-13.401 13.401s6.012 13.401 13.401 13.401 13.401-6.011 13.401-13.401c.001-7.39-6.011-13.401-13.401-13.401z"
      ></path>
      <path
        fill="#FFF"
        d="M222.693 40.962H232.622V159.40699999999998H222.693z"
        transform="scale(-1) rotate(11.227 1019.269 -2316.176)"
      ></path>
    </svg>
  );
};

export default withBaseIcon()(IconTelephoneGame);

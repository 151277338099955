import React, {
  FC,
  useRef,
  useCallback,
  MouseEventHandler,
  TouchEvent,
  MouseEvent,
  useState,
  useLayoutEffect,
} from "react";
import { Nullish } from "../../../utils/types";
import ColorPicker from "../ColorPicker";
import WidthSelector from "../WidthSelector";
import Button from "../display/Button";

type MouseMovement = {
  x: number;
  y: number;
  isDrag: boolean;
  width: number;
  color?: string;
};

const initializeStroke = (ctx: CanvasRenderingContext2D) => {
  ctx.lineJoin = "round";
  ctx.lineCap = "round";
  ctx.beginPath();
};

const restartStroke = (
  ctx: CanvasRenderingContext2D,
  mouseMovement: MouseMovement
) => {
  ctx.stroke();
  initializeStroke(ctx);
  const { x: prevX, y: prevY } = mouseMovement;
  ctx.moveTo(prevX, prevY);
};

const redraw = (
  ctx: Nullish<CanvasRenderingContext2D>,
  mouseMovements: MouseMovement[]
) => {
  if (ctx == null || mouseMovements.length === 0) {
    return;
  }

  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // Clears the canvas
  ctx.fillStyle = "rgb(255,255,255)";
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.strokeStyle = mouseMovements[0]?.color ?? "black";
  ctx.lineWidth = mouseMovements[0]?.width ?? 5;
  initializeStroke(ctx);

  ctx.beginPath();
  ctx.moveTo(mouseMovements[0]?.x, mouseMovements[0]?.y);
  for (var i = 1; i < mouseMovements.length; i++) {
    const { width, color, isDrag, x, y } = mouseMovements[i];
    const hasNewWidth = width !== ctx.lineWidth;
    const hasNewColor = color && color !== ctx.strokeStyle;

    if (hasNewWidth || hasNewColor) {
      restartStroke(ctx, mouseMovements[i - 1]);
      if (hasNewWidth) ctx.lineWidth = width;
      if (hasNewColor && color) ctx.strokeStyle = color;
    }

    // if they picked up the "pencil", we don't want to draw a line connecting the last point and the new point
    if (!isDrag) {
      ctx.moveTo(x, y);
    }

    ctx.lineTo(x, y);
  }
  ctx.stroke();
};

const strokeWidths = [4, 8, 16, 32];
const useStrokeWidth = (): [number, () => void, () => void] => {
  const [strokeWidthIndex, setStrokeWidthIndex] = useState(0);

  return [
    strokeWidths[strokeWidthIndex],
    () => {
      setStrokeWidthIndex((prevIndex) =>
        prevIndex === strokeWidths.length - 1 ? 0 : prevIndex + 1
      );
    },
    () => {
      setStrokeWidthIndex((prevIndex) =>
        prevIndex === 0 ? strokeWidths.length - 1 : prevIndex - 1
      );
    },
  ];
};

const getIsTouchEvent = (
  event: TouchEvent<HTMLCanvasElement> | MouseEvent<HTMLCanvasElement>
): event is TouchEvent<HTMLCanvasElement> => {
  return (event as TouchEvent<HTMLCanvasElement>).touches != null;
};

const getCoordsForMouseOrTouchEvent = (
  event: TouchEvent<HTMLCanvasElement> | MouseEvent<HTMLCanvasElement>
) => {
  const coords = { x: 0, y: 0 };
  if (getIsTouchEvent(event)) {
    const touch = event.touches[0];
    coords.x = touch.pageX;
    coords.y = touch.pageY;
  } else {
    coords.x = event.pageX;
    coords.y = event.pageY;
  }
  return coords;
};

const BORDER_WIDTH = 2;
const IMG_FORMAT = "image/jpeg";
const IMG_QUALITY = 0.8;

type CanvasProps = {
  width?: number;
  height?: number;
  onSubmitDrawing: (drawing: string) => any;
};
const Canvas: FC<CanvasProps> = ({ width, height, onSubmitDrawing }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const context = useRef<Nullish<CanvasRenderingContext2D>>();
  const isPainting = useRef<boolean>(false);
  const mouseMovements = useRef<MouseMovement[]>([]);
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeWidth, incrementStrokeWidth] = useStrokeWidth();

  const handleResize = useCallback(() => {
    if (
      canvasRef.current != null &&
      window.screen.width - BORDER_WIDTH * 2 < 600
    ) {
      canvasRef.current.width = window.screen.width - BORDER_WIDTH * 2;
    }
  }, []);

  // initialize context on mount
  useLayoutEffect(() => {
    context.current = canvasRef.current?.getContext("2d");
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  type CanvasEventType =
    | TouchEvent<HTMLCanvasElement>
    | MouseEvent<HTMLCanvasElement>;

  const handleMouseDown = useCallback(
    (event: CanvasEventType) => {
      if (getIsTouchEvent(event)) {
        event.preventDefault();
      }
      const { x, y } = getCoordsForMouseOrTouchEvent(event);
      isPainting.current = true;
      mouseMovements.current.push({
        x: x - (canvasRef.current?.offsetLeft ?? 0),
        y: y - (canvasRef.current?.offsetTop ?? 0),
        isDrag: false,
        width: strokeWidth,
        color: strokeColor,
      });

      redraw(context.current, mouseMovements.current);
    },
    [strokeWidth, strokeColor]
  );

  const handleMouseMove = useCallback(
    (event: CanvasEventType) => {
      if (getIsTouchEvent(event)) {
        event.preventDefault();
      }
      if (isPainting.current) {
        const { x, y } = getCoordsForMouseOrTouchEvent(event);

        if (getIsTouchEvent(event)) {
          event.preventDefault();
        }
        isPainting.current = true;
        mouseMovements.current.push({
          x: x - (canvasRef.current?.offsetLeft ?? 0),
          y: y - (canvasRef.current?.offsetTop ?? 0),
          isDrag: true,
          width: strokeWidth,
          color: strokeColor,
        });

        redraw(context.current, mouseMovements.current);
      }
    },
    [strokeWidth, strokeColor]
  );

  const handleMouseUp = useCallback((event: CanvasEventType) => {
    if (getIsTouchEvent(event)) {
      event.preventDefault();
    }
    isPainting.current = false;
  }, []);

  const handleMouseLeave: MouseEventHandler<HTMLCanvasElement> = useCallback(() => {
    isPainting.current = false;
  }, []);

  const clearCanvas = useCallback(() => {
    context.current?.clearRect(
      0,
      0,
      context.current.canvas.width,
      context.current.canvas.height
    );

    mouseMovements.current = [];
  }, []);

  return (
    <div>
      <canvas
        style={{
          border: `${BORDER_WIDTH}px solid black`,
          boxSizing: "border-box",
          touchAction: "none",
        }}
        onMouseLeave={handleMouseLeave}
        onTouchCancel={(e) => {
          e.preventDefault();
        }}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMouseMove={handleMouseMove}
        onTouchMove={handleMouseMove}
        ref={canvasRef}
        width={width}
        height={height}
      />
      <div className="flex justify-between items-start">
        <ColorPicker setColor={setStrokeColor} />
        <WidthSelector
          maxWidth={strokeWidths[strokeWidths.length - 1]}
          currentWidth={strokeWidth}
          incrementWidth={incrementStrokeWidth}
          color={strokeColor}
        />
        <Button
          className="self-end"
          kind="destructive"
          style={{ height: "fit-content" }}
          onClick={clearCanvas}
        >
          clear
        </Button>
        <Button
          className="self-end"
          onClick={() => {
            const drawing = canvasRef.current?.toDataURL(
              IMG_FORMAT,
              IMG_QUALITY
            );
            if (drawing != null) {
              onSubmitDrawing(drawing);
            }
          }}
        >
          submit
        </Button>
      </div>
    </div>
  );
};

Canvas.defaultProps = {
  width: 490,
  height: 220,
};

export default Canvas;

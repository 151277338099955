import { GetWordsResponse } from "../../shared/types";

const URL = "https://7igqon85j5.execute-api.us-east-2.amazonaws.com/prod";

class WordsAPI {
  async getWords() {
    const response = await fetch(`${URL}/getWords`, { method: "GET" });
    // TODO: make this more robust
    const wordsResponse = await response.json();

    return (wordsResponse as GetWordsResponse).data;
  }
}

export default WordsAPI;

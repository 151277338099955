import React from "react";
import { SimpleIconComponent } from "./types";
import { withBaseIcon } from "./BaseIcon";

const IconBronzeMedal: SimpleIconComponent = ({ className, style }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#0083fd"
        d="M233.973 381.813l-76.746-25.582-22.498-.126L95.376 469.17c-1.254 3.601 1.664 7.284 5.457 6.886l55.163-5.776a5.206 5.206 0 014.181 1.455l39.655 38.779c2.726 2.666 7.3 1.591 8.553-2.01l39.611-113.805z"
      ></path>
      <path
        fill="#0072fc"
        d="M247.996 394.699L256 371.704l-113.29-38.526-7.98 22.927c32.17 22.955 71.122 37.007 113.266 38.594z"
      ></path>
      <path
        fill="#0083fd"
        d="M278.027 381.813l76.746-25.582 22.498-.126 39.354 113.066c1.254 3.601-1.664 7.284-5.457 6.886l-55.163-5.776a5.203 5.203 0 00-4.18 1.455l-39.655 38.779c-2.726 2.666-7.3 1.591-8.553-2.01L264.006 394.7z"
      ></path>
      <path
        fill="#0072fc"
        d="M264.004 394.699L256 371.704l113.29-38.526 7.98 22.927c-32.17 22.955-71.122 37.007-113.266 38.594z"
      ></path>
      <path
        fill="#ff9838"
        d="M256 356.105c-185.62 0-185.774-176.517-185.62-179.62C75.259 78.194 156.498 0 256 0s180.741 78.194 185.62 176.485c.154 3.103 0 179.62-185.62 179.62z"
      ></path>
      <path
        fill="#ff8709"
        d="M256 352.969c-99.502 0-180.738-78.194-185.618-176.484a188.956 188.956 0 00-.234 9.367c0 102.643 83.209 185.852 185.852 185.852s185.852-83.209 185.852-185.852c0-3.141-.08-6.264-.234-9.367-4.88 98.29-86.116 176.484-185.618 176.484z"
      ></path>
      <path
        fill="#ce7430"
        d="M402.412 185.852c0 62.694-65.551 137.412-146.412 137.412s-146.412-78.829-146.412-137.412c0-3.147.099-6.27.295-9.368C114.713 99.988 178.286 39.44 256 39.44s141.287 60.548 146.117 137.044c.196 3.098.295 6.221.295 9.368z"
      ></path>
      <path
        fill="#bc672e"
        d="M256 313.529c-77.713 0-141.274-60.55-146.105-137.044a148.255 148.255 0 00-.307 9.367c0 80.861 65.551 146.412 146.412 146.412s146.412-65.551 146.412-146.412c0-3.148-.111-6.269-.307-9.367-4.831 76.494-68.392 137.044-146.105 137.044z"
      ></path>
      <path
        fill="#ffaa5c"
        d="M256.185 272.82c-31.533 0-51.597-21.737-55.459-47.527-.351-2.346-.462-7.381-.462-7.381 2.685-6.841 9.347-11.685 17.14-11.685 10.165 0 18.406 8.24 18.406 18.406 0 11.235 9.14 20.375 20.375 20.375s20.376-9.14 20.376-20.375a20.25 20.25 0 00-2.287-9.374s-1.382-17.356-17.103-17.356c-15.77 0-16.826-21.428-16.826-21.428 3.207-5.405 9.101-9.028 15.841-9.028 11.235 0 20.376-9.14 20.376-20.375s-9.141-20.375-20.376-20.375-20.375 9.14-20.375 20.375c0 10.166-8.241 18.406-18.406 18.406s-18.406-8.24-18.406-18.406c0-31.532 25.654-57.186 57.186-57.186 31.533 0 57.187 25.654 57.187 57.186 0 14.952-5.766 28.58-15.194 38.781 7.418 8.026 12.569 18.174 14.429 29.419 0 0 .057 6.172-.478 9.315-4.563 26.822-24.411 48.233-55.944 48.233z"
      ></path>
      <g fill="#ea9957">
        <path d="M256.185 185.523c-6.74 0-12.617-3.634-15.823-9.038a18.287 18.287 0 00-2.582 9.367c0 10.166 8.24 18.406 18.406 18.406 7.857 0 14.677 4.478 18.075 11.009a20.232 20.232 0 002.301-9.367c-.001-11.237-9.142-20.377-20.377-20.377zM256.185 263.085c-27.409 0-50.367-19.387-55.905-45.166a18.333 18.333 0 00-1.282 6.715c0 31.533 25.654 57.187 57.187 57.187s57.187-25.654 57.187-57.187c0-3.189-.277-6.312-.781-9.36-4.488 27.086-28.066 47.811-56.406 47.811z"></path>
      </g>
    </svg>
  );
};

export default withBaseIcon()(IconBronzeMedal);

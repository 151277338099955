// import { Player } from "./player";
// import { ClientGameSession } from "./gameSession";

import { DrawingDbItem } from "./drawing";

export interface ErrorBody {
  error: {
    reason: string;
  };
}

export interface WSResponse<Action, Data> {
  action: Action;
  data: Data;
}

export type ReconnectData = {
  gameId: string;
  playerId: string;
};

export type StartGameData = {
  gameSessionId: string;
};

export type StartNewGameData = {
  gameSessionId: string;
};

interface SubmitEntryData {
  gameId: string;
  playerId: string;
  currentRoundIndex: number;
  currentTurnIndex: number;
}

export interface SubmitDrawingData extends SubmitEntryData {
  drawingId: string;
}

export interface SubmitGuessData extends SubmitEntryData {
  guess: string;
}

export type ScoreEntriesData = {
  gameId: string;
  awardingPlayerId: string;
  playerIds: string[];
  currentRoundIndex: number;
  currentTurnIndex: number;
  shouldGetPoints: boolean;
};

export type LikeEntryData = {
  gameId: string;
  awardingPlayerId: string;
  playerId: string;
  // TODO: do we need?
  currentRoundIndex: number;
  currentTurnIndex: number;
};

export type AddPlayerData = {
  playerName: string;
  playerId: string;
  gameSessionId: string;
};

export type ChangeNameData = {
  playerName: string;
  playerId: string;
  gameSessionId: string;
};

export type ChooseWordData = {
  gameSessionId: string;
  playerId: string;
  word: string;
};

export type GoToNextTurnData = {
  gameSessionId: string;
  currentTurnIndex: number;
};

export type GoToNextRoundData = {
  gameSessionId: string;
  currentRoundIndex: number;
};

export type StartScoringData = {
  gameSessionId: string;
  currentRoundIndex: number;
};

export type SaveDrawingData = {
  dataUrl: string;
};

export enum GameSessionAction {
  Connect = "connect",
  Reconnect = "reconnect",
  ChooseWord = "chooseWord",
  AddPlayer = "addPlayer",
  ChangeName = "changeName",
  StartGame = "startGame",
  StartNewGame = "startNewGame",
  SubmitDrawing = "submitDrawing",
  SubmitGuess = "submitGuess",
  GoToNextTurn = "goToNextTurn",
  GoToNextRound = "goToNextRound",
  StartScoring = "startScoring",
  ScoreEntries = "scoreEntries",
  LikeEntry = "likeEntry",
}

export interface GameSessionActionData {
  [GameSessionAction.Connect]: undefined;
  [GameSessionAction.Reconnect]: ReconnectData;
  [GameSessionAction.ChooseWord]: ChooseWordData;
  [GameSessionAction.AddPlayer]: AddPlayerData;
  [GameSessionAction.ChangeName]: ChangeNameData;
  [GameSessionAction.StartGame]: StartGameData;
  [GameSessionAction.StartNewGame]: StartNewGameData;
  [GameSessionAction.SubmitDrawing]: SubmitDrawingData;
  [GameSessionAction.SubmitGuess]: SubmitGuessData;
  [GameSessionAction.GoToNextTurn]: GoToNextTurnData;
  [GameSessionAction.GoToNextRound]: GoToNextRoundData;
  [GameSessionAction.StartScoring]: StartScoringData;
  [GameSessionAction.ScoreEntries]: ScoreEntriesData;
  [GameSessionAction.LikeEntry]: LikeEntryData;
}

// // TODO: do this right
// export interface GameSessionActionResponseData {
//   [GameSessionAction.ChooseWord]: WSResponse<
//     GameSessionAction.ChooseWord,
//     ClientGameSession
//   >;
//   [GameSessionAction.AddPlayer]: WSResponse<
//     GameSessionAction.AddPlayer,
//     ClientGameSession
//   >;
//   [GameSessionAction.StartGame]: WSResponse<
//     GameSessionAction.StartGame,
//     ClientGameSession
//   >;
// }

export type GetWordsResponse = {
  data: string[];
};

export type GetDrawingResponse = {
  data: DrawingDbItem;
};

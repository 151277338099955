import React, { FC } from "react";
import cx from "classnames";
import { Player } from "../../../shared/types";

type PlayerProps = {
  player: Player;
  className?: string;
  playerId?: string;
};

const PlayerInfo: FC<PlayerProps> = ({ player, className, playerId }) => {
  const { id, name } = player;

  return (
    <div
      className={cx(className, {
        "font-bold": playerId === id,
      })}
    >
      <span className="truncate">{name}</span>
      {id === playerId ? " (you)" : ""}
    </div>
  );
};

export default PlayerInfo;

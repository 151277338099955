import React, { FC, ComponentPropsWithoutRef } from "react";
import { Link as RRLink } from "react-router-dom";
import cx from "classnames";
import { isString } from "lodash/fp";

const externalLinkRegex = /^https?/;
const getIsExternal = (href: string) => externalLinkRegex.test(href);

interface LinkProps extends ComponentPropsWithoutRef<RRLink> {
  kind?: "normal" | "light" | "dark";
}

const Link: FC<LinkProps> = ({
  className,
  to,
  children,
  kind = "normal",
  ...props
}) => {
  const classes = cx(
    {
      "text-blue-500 hover:text-blue-800": kind === "normal",
      "text-gray-300 hover:text-gray-100": kind === "light",
      "text-gray-700 hover:text-gray-900": kind === "dark",
    },
    className
  );

  if (isString(to) && getIsExternal(to)) {
    return (
      <a href={to} className={classes}>
        {children}
      </a>
    );
  }

  return (
    <RRLink className={classes} to={to} {...props}>
      {children}
    </RRLink>
  );
};

export default Link;

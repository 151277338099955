import { GameSession } from "../../shared/types";

export const getHasPlayerVoted = (
  fullGameState: GameSession | undefined,
  indexOfPlayer: number,
  currentTurnIndex: number,
  playerId: string
) => {
  if (fullGameState != null) {
    const { playerIds, playerRoundsMap, playerPointsMap } = fullGameState;
    if (
      playerRoundsMap != null &&
      playerIds != null &&
      playerPointsMap != null &&
      indexOfPlayer < playerIds.length
    ) {
      const numPlayers = playerIds.length;
      const totalNumVotes = Object.values(playerPointsMap)
        .map((pointAwards) =>
          pointAwards.filter((award) => award.awardedBy === playerId)
        )
        .reduce((totalNumVotes, pointAwards) => {
          return totalNumVotes + pointAwards.length;
        }, 0);
      const totalNumberOfEntriesPerWord =
        playerIds.length % 2 === 0 ? playerIds.length : playerIds.length - 1;

      const totalNumVotesForCurrentPlayerIndex = currentTurnIndex + 1;

      const totalNumVotesForAlreadyScoredPlayerIndices =
        indexOfPlayer * totalNumberOfEntriesPerWord;

      const totalNumVotesForAlreadyScoredRounds =
        fullGameState.currentRoundIndex *
        totalNumberOfEntriesPerWord *
        numPlayers;

      const hasPlayerVoted =
        totalNumVotes >=
        totalNumVotesForCurrentPlayerIndex +
          totalNumVotesForAlreadyScoredPlayerIndices +
          totalNumVotesForAlreadyScoredRounds;

      return hasPlayerVoted;
    }
  }

  return false;
};

export const getHaveAllPlayersVoted = (
  fullGameState: GameSession | undefined,
  indexOfPlayer: number,
  currentTurnIndex: number
) => {
  if (fullGameState != null) {
    const { playerIds, playerRoundsMap, playerPointsMap } = fullGameState;
    if (
      playerRoundsMap != null &&
      playerIds != null &&
      playerPointsMap != null &&
      indexOfPlayer < playerIds.length
    ) {
      const numPlayers = playerIds.length;
      const totalNumVotes = Object.values(playerPointsMap).reduce(
        (totalNumVotes, pointAwards) => {
          return totalNumVotes + pointAwards.length;
        },
        0
      );
      const totalNumberOfEntriesPerWord =
        playerIds.length % 2 === 0 ? playerIds.length : playerIds.length - 1;

      const totalNumVotesForCurrentPlayerIndex =
        (currentTurnIndex + 1) * numPlayers;

      const totalNumVotesForAlreadyScoredPlayerIndices =
        indexOfPlayer * totalNumberOfEntriesPerWord * numPlayers;

      const totalNumVotesForAlreadyScoredRounds =
        fullGameState.currentRoundIndex *
        Math.pow(numPlayers, 2) *
        totalNumberOfEntriesPerWord;

      const haveAllPlayersVoted =
        totalNumVotes >=
        totalNumVotesForCurrentPlayerIndex +
          totalNumVotesForAlreadyScoredPlayerIndices +
          totalNumVotesForAlreadyScoredRounds;

      return haveAllPlayersVoted;
    }
  }

  return false;
};

export const getIsLastRound = (
  fullGameState: GameSession | undefined,
  indexOfPlayer: number,
  currentTurnIndex: number
) => {
  if (fullGameState != null) {
    const { playerIds, playerRoundsMap, playerPointsMap } = fullGameState;
    if (
      playerRoundsMap != null &&
      playerIds != null &&
      playerPointsMap != null &&
      indexOfPlayer < playerIds.length
    ) {
      const numPlayers = playerIds.length;
      const maxTurns = numPlayers % 2 === 0 ? numPlayers : numPlayers - 1;
      const isLastRound = (currentTurnIndex ?? 0) >= maxTurns - 1;

      return isLastRound;
    }
  }

  return false;
};

import { makeId } from "../../shared/id";

type PlayerIDStorage = {
  id: string;
  name?: string;
};

let playerId = makeId();
let playerName;

try {
  const serializedPlayerIdInfo = localStorage.getItem("playerIdInfo");
  if (typeof serializedPlayerIdInfo === "string") {
    const playerIdInfo = JSON.parse(serializedPlayerIdInfo) as PlayerIDStorage;
    playerId = playerIdInfo.id;
    playerName = playerIdInfo.name;
  } else {
    localStorage.setItem("playerIdInfo", JSON.stringify({ id: playerId }));
  }
} catch (e) {
  console.error(e);
}

export const playerInfo = {
  playerId,
  playerName,
};

export const setPlayerName = (name: string) => {
  playerInfo.playerName = name;
  try {
    const serializedPlayerIdInfo = localStorage.getItem("playerIdInfo");
    if (typeof serializedPlayerIdInfo === "string") {
      const playerIdInfo = JSON.parse(
        serializedPlayerIdInfo
      ) as PlayerIDStorage;
      playerIdInfo.name = name;
      localStorage.setItem("playerIdInfo", JSON.stringify(playerIdInfo));
    }
  } catch (e) {
    console.error(e);
  }
};

import React, {
  createContext,
  FC,
  useMemo,
  useState,
  useCallback,
} from "react";
import { GameSession } from "../../../shared/types";

type FullGameStateContext = {
  fullGameState?: GameSession;
  setFullGameState: (fullGameState: GameSession) => void;
  resetFullGameState: () => void;
};

export const FullGameStateContext = createContext<FullGameStateContext>({
  fullGameState: undefined,
  setFullGameState: () => {},
  resetFullGameState: () => {},
});

export const FullGameStateProvider: FC = ({ children }) => {
  const [fullGameState, setFullGameState] = useState<GameSession>();
  const resetFullGameState = useCallback(
    () => setFullGameState(undefined as any),
    []
  );
  const providerValue = useMemo(
    () => ({
      fullGameState,
      setFullGameState,
      resetFullGameState,
    }),
    [fullGameState, resetFullGameState]
  );

  return (
    <FullGameStateContext.Provider value={providerValue}>
      {children}
    </FullGameStateContext.Provider>
  );
};

export default FullGameStateProvider;

import React from "react";
import { SimpleIconComponent } from "./types";
import { withBaseIcon } from "./BaseIcon";

const IconTropy: SimpleIconComponent = ({ className, style }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#f1cb86"
        d="M444.034 51.2H67.967c-9.426 0-17.067 7.64-17.067 17.066v51.2c0 37.642 30.624 68.267 68.267 68.267h273.668c37.643 0 68.267-30.624 68.267-68.267v-51.2C461.1 58.84 453.46 51.2 444.034 51.2zm-17.067 68.267c0 18.851-15.282 34.133-34.133 34.133H119.167c-18.851 0-34.133-15.282-34.133-34.133v-25.6a8.533 8.533 0 018.533-8.533h324.866a8.533 8.533 0 018.533 8.533v25.6z"
      ></path>
      <path
        fill="#ffe07d"
        d="M289.833 221.867c37.703 0 68.267-30.564 68.267-68.267V25.837H153.3V153.6c0 37.703 30.564 68.267 68.267 68.267v6.23h68.267v-6.23z"
      ></path>
      <path
        fill="#f1cb86"
        d="M334.406 25.837V153.6c0 37.703-30.564 68.267-68.267 68.267v6.23h23.694v-6.23c37.703 0 68.267-30.564 68.267-68.267V25.837z"
      ></path>
      <path fill="#edf6ff" d="M238.633 244.579h34.133v56.975h-34.133z"></path>
      <path fill="#d8e2f3" d="M249.072 244.579h23.694v56.975h-23.694z"></path>
      <path
        fill="#edf6ff"
        d="M263.09 85.747l6.514 13.198a8.241 8.241 0 006.205 4.508l14.565 2.116c6.76.982 9.459 9.289 4.568 14.057l-10.539 10.273a8.24 8.24 0 00-2.37 7.295l2.488 14.506c1.155 6.732-5.912 11.867-11.958 8.688l-13.027-6.849a8.243 8.243 0 00-7.67 0l-13.027 6.849c-6.046 3.179-13.113-1.956-11.958-8.688l2.488-14.506a8.244 8.244 0 00-2.37-7.295l-10.539-10.273c-4.892-4.768-2.192-13.075 4.567-14.057l14.565-2.116a8.241 8.241 0 006.205-4.508l6.514-13.198c3.021-6.125 11.756-6.125 14.779 0z"
      ></path>
      <path
        fill="#ffe07d"
        d="M144.233 0h222.933a8 8 0 018 8v10.133c0 8.837-7.164 16-16 16H152.233c-8.837 0-16-7.163-16-16V8a8 8 0 018-8z"
      ></path>
      <path
        fill="#f1cb86"
        d="M366.925 0h-23.634a8.24 8.24 0 018.241 8.241v9.409c0 9.103-7.379 16.483-16.483 16.483h23.634c9.103 0 16.483-7.38 16.483-16.483V8.241A8.24 8.24 0 00366.925 0z"
      ></path>
      <path
        fill="#ffe07d"
        d="M221.566 221.867h68.267V240c0 8.837-7.163 16-16 16h-36.267c-8.837 0-16-7.163-16-16zM289.833 329.418h-68.267v-23.284c0-8.837 7.163-16 16-16h36.267c8.837 0 16 7.163 16 16z"
      ></path>
      <path
        fill="#f1cb86"
        d="M266.139 221.867v17.65c0 9.103-7.38 16.483-16.483 16.483h23.694c9.104 0 16.483-7.38 16.483-16.483v-17.65zM273.35 290.133h-23.694c9.103 0 16.483 7.38 16.483 16.483v22.801h23.694v-22.801c0-9.103-7.38-16.483-16.483-16.483z"
      ></path>
      <path
        fill="#817d84"
        d="M153.3 460.8l17.066-102.4v-8.785h170.667v8.785L358.1 460.8v9.561H153.3z"
      ></path>
      <path
        fill="#756e79"
        d="M341.033 358.4v-8.785h-23.694v8.785l17.067 102.4v9.561H358.1V460.8z"
      ></path>
      <path
        fill="#ffe07d"
        d="M367.166 512H144.233a8 8 0 01-8-8v-27.2c0-8.837 7.163-16 16-16h206.933c8.836 0 16 7.163 16 16V504a8 8 0 01-8 8zM161.3 324.267h188.8a8 8 0 018 8V342.4c0 8.837-7.163 16-16 16H169.3c-8.837 0-16-7.163-16-16v-10.133a8 8 0 018-8z"
      ></path>
      <g fill="#f1cb86">
        <path d="M349.858 324.267h-23.694a8.241 8.241 0 018.241 8.241v9.409c0 9.104-7.38 16.483-16.483 16.483h23.694c9.103 0 16.483-7.379 16.483-16.483v-9.409a8.24 8.24 0 00-8.241-8.241zM358.683 460.8h-23.694c9.103 0 16.483 7.379 16.483 16.483v26.475a8.24 8.24 0 01-8.241 8.241h23.694a8.24 8.24 0 008.241-8.241v-26.475c0-9.103-7.38-16.483-16.483-16.483z"></path>
      </g>
      <path
        fill="#edf6ff"
        d="M281.833 426.667h-52.267a8 8 0 01-8-8v-18.133a8 8 0 018-8h52.267a8 8 0 018 8v18.133a8 8 0 01-8 8z"
      ></path>
      <path
        fill="#d8e2f3"
        d="M281.591 392.533h-23.694a8.24 8.24 0 018.241 8.241v17.65a8.24 8.24 0 01-8.241 8.241h23.694a8.24 8.24 0 008.241-8.241v-17.65a8.24 8.24 0 00-8.241-8.241z"
      ></path>
    </svg>
  );
};

export default withBaseIcon()(IconTropy);

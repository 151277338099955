import React, { FC } from "react";
import Link from "../display/Link";

const Footer: FC = () => {
  return (
    <footer className="flex flex-col justify-center items-center bg-gray-900 text-white h-16">
      <div className="text-xs">
        Icons made by{" "}
        <Link to="http://www.freepik.com/" title="Freepik">
          Freepik
        </Link>{" "}
        from{" "}
        <Link to="https://www.flaticon.com/" title="Flaticon">
          {" "}
          www.flaticon.com
        </Link>
      </div>
    </footer>
  );
};

export default Footer;

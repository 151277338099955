import React, {
  createContext,
  FC,
  useMemo,
  useReducer,
  useCallback,
} from "react";

enum PageActionType {
  IncTurnIndex = "INC_TURN_INDEX",
  IncPlayerIndex = "INC_PLAYER_INDEX",
  Reset = "RESET",
}

type PageIndexValues = {
  currentTurnIndex?: number;
  indexOfPlayerShowing?: number;
};

const reset = () => ({
  type: PageActionType.Reset,
});

const incrementTurnIndex = () => ({
  type: PageActionType.IncTurnIndex,
});
const incrementPlayerIndex = () => ({
  type: PageActionType.IncPlayerIndex,
});

type Actions =
  | ReturnType<typeof reset>
  | ReturnType<typeof incrementTurnIndex>
  | ReturnType<typeof incrementPlayerIndex>;

const initialState: Required<PageIndexValues> = {
  currentTurnIndex: 1,
  indexOfPlayerShowing: 0,
};

const pageReducer = (
  state: Required<PageIndexValues>,
  action: Actions
): Required<PageIndexValues> => {
  switch (action.type) {
    case PageActionType.IncTurnIndex:
      return {
        ...state,
        currentTurnIndex: state.currentTurnIndex + 2,
      };
    case PageActionType.IncPlayerIndex:
      return {
        ...state,
        indexOfPlayerShowing: state.indexOfPlayerShowing + 1,
        currentTurnIndex: 1,
      };
    case PageActionType.Reset:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

type ScoringStateContext = {
  currentTurnIndex: number;
  indexOfPlayerShowing: number;
  reset: () => void;
  incrementPlayerIndex: () => void;
  incrementTurnIndex: () => void;
};

export const ScoringStateContext = createContext<ScoringStateContext>({
  ...initialState,
  reset: () => {},
  incrementPlayerIndex: () => {},
  incrementTurnIndex: () => {},
});

export const ScoringStateProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(pageReducer, initialState);

  const resetAction = useCallback(() => dispatch(reset()), []);
  const incrementPlayerIndexAction = useCallback(
    () => dispatch(incrementPlayerIndex()),
    []
  );
  const incrementTurnIndexAction = useCallback(
    () => dispatch(incrementTurnIndex()),
    []
  );

  const providerValue = useMemo(
    () => ({
      ...state,
      reset: resetAction,
      incrementPlayerIndex: incrementPlayerIndexAction,
      incrementTurnIndex: incrementTurnIndexAction,
    }),
    [incrementPlayerIndexAction, incrementTurnIndexAction, resetAction, state]
  );

  return (
    <ScoringStateContext.Provider value={providerValue}>
      {children}
    </ScoringStateContext.Provider>
  );
};

export default ScoringStateProvider;

import React, { FC } from "react";
import cx from "classnames";
import Button from "../display/Button";

type SelectWordProps = {
  words: string[];
  selectWord: (word: string) => any;
};

const SelectWord: FC<SelectWordProps> = ({ words, selectWord }) => {
  return (
    <>
      <h2 className="text-2xl font-medium">Choose a word</h2>
      <div className="flex justify-around w-full">
        {words.map((word, i) => {
          return (
            <Button
              kind="secondary"
              className={cx({ "ml-12": i > 0 }, "w-1/3")}
              onClick={() => selectWord(word)}
            >
              {word}
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default SelectWord;

import React, { FC, useContext } from "react";
import cx from "classnames";
import PlayerInfo from "./PlayerInfo";
import { PlayerIdContext } from "../Providers/PlayerId";
import { GameStateContext } from "../Providers/GameState";
import { getDidAllPlayersChooseWord } from "../../utils/gameState";
import { ProgressIndicator } from "./ProgressIndicator";
import { motion } from "framer-motion";

type PlayerListProps = {
  className?: string;
};

const PlayerList: FC<PlayerListProps> = ({ className }) => {
  const { gameState } = useContext(GameStateContext);
  const { playerId } = useContext(PlayerIdContext);

  if (gameState.players == null) {
    return <div className={className} />;
  }

  const players = gameState.players.map((player) => {
    const info =
      player.id === playerId
        ? gameState.personalRoundInfo
        : gameState.playersRoundInfo?.find(
            (info) => info.playerId === player.id
          );

    return {
      ...player,
      isFinished: !getDidAllPlayersChooseWord(gameState)
        ? info?.didChooseWord
        : info?.isCurrentActionComplete,
    };
  });

  return (
    <div className={cx(className, "border-solid border-gray-900 border-2")}>
      {players.map(({ isFinished, ...player }, index) => {
        return (
          <motion.li
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            key={player.id}
            className={cx(
              { "border-gray-900 border-t-2 border-dashed": index > 0 },
              "flex p-2 items-center truncate"
            )}
          >
            <ProgressIndicator
              isWorking={Boolean(
                gameState.isGameActive && isFinished === false
              )}
            />
            <PlayerInfo playerId={playerId} player={player} />
          </motion.li>
        );
      })}
    </div>
  );
};

export default PlayerList;

import React, { FC } from "react";
import PlayerList from "../Players/PlayerList";
import PlayerListEndGame from "../Players/PlayerListEndGame";
import { Route, Switch } from "react-router-dom";

const ContentLayout: FC = ({ children }) => {
  return (
    <div className="flex flex-col md:flex-row mx-auto">
      <section className="flex-grow">{children}</section>

      <Switch>
        <Route path="/games/:gameId/scoring/:roundIndex" exact>
          <PlayerListEndGame className="md:order-first md:mr-16 mb-auto md:w-1/4 md:mt-0 mt-8" />
        </Route>
        <Route>
          <PlayerList className="md:order-first md:mr-16 mb-auto md:w-1/4 md:mt-0 mt-8" />
        </Route>
      </Switch>
    </div>
  );
};

export default ContentLayout;

import React, { FC, ComponentType, CSSProperties } from "react";
import cx from "classnames";
import { SimpleIconProps } from "./types";

interface InnerBaseIconProps extends SimpleIconProps {
  size?: "s" | "m" | "l" | "xl";
  Icon: ComponentType<SimpleIconProps>;
}

export type BaseIconProps = Omit<InnerBaseIconProps, "Icon">;
export type IconComponent = ComponentType<BaseIconProps>;

const BaseIcon = ({
  className,
  size = "s",
  Icon,
  style,
}: InnerBaseIconProps) => {
  const baseStyle: CSSProperties = { ...style };
  switch (size) {
    case "xl":
      baseStyle.maxWidth = "12rem";
      baseStyle.maxHeight = "12rem";
      break;
    case "l":
      baseStyle.maxWidth = "5rem";
      baseStyle.maxHeight = "5rem";
      break;
    case "m":
      baseStyle.maxWidth = "2.5rem";
      baseStyle.maxHeight = "2.5rem";
      break;
    case "s":
      baseStyle.maxWidth = "1.5rem";
      baseStyle.maxHeight = "1.5rem";
      break;
    default:
      break;
  }

  return <Icon style={baseStyle} className={cx("w-full h-full", className)} />;
};

export default BaseIcon;

export const withBaseIcon = () => (
  BaseComponent: ComponentType<SimpleIconProps>
): FC<BaseIconProps> => {
  const Comp = (props: BaseIconProps) => {
    return <BaseIcon {...props} Icon={BaseComponent} />;
  };

  Comp.displayName = `withBaseIcon(${BaseComponent.displayName})`;

  return Comp;
};

import React, { FC } from "react";
import Button from "../display/Button";

type ColorPickerProps = {
  setColor: (color: string) => void;
};

const colors = [
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "brown",
  "black",
  "gray",
  "white",
];

const ColorPicker: FC<ColorPickerProps> = ({ setColor }) => {
  return (
    <div className="w-1/4">
      <span>choose color:</span>
      <div>
        {colors.map((color) => (
          <Button
            key={color}
            kind="empty"
            onClick={() => {
              setColor(color);
            }}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;

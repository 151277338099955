import React, { FC } from "react";
import { useForm } from "react-hook-form";
import Input from "../../display/Input";
import Button from "../../display/Button";

export type SubmitGuessFormData = {
  guess: string;
};

type SubmitGuessFormProps = {
  onSubmit: (data: SubmitGuessFormData) => any;
};

const SubmitGuessForm: FC<SubmitGuessFormProps> = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm<SubmitGuessFormData>();

  return (
    <form
      className="flex items-end flex-no-wrap"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        label="guess:"
        ref={register({ required: true })}
        type="text"
        name="guess"
      />
      <Button className="ml-4 flex-shrink-0" type="submit">
        submit guess
      </Button>
    </form>
  );
};

export default SubmitGuessForm;

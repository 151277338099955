import React, { createContext, FC, useState, useMemo } from "react";
import { playerInfo, setPlayerName } from "../../playerInfo/storage";

type PlayerInfoContextType = {
  playerId: string;
  playerName?: string;
  setPlayerName: (name: string) => void;
};

export const PlayerIdContext = createContext<PlayerInfoContextType>({
  ...playerInfo,
  setPlayerName: () => {},
});

export const PlayerIdProvider: FC = ({ children }) => {
  const [localPlayerInfo, setPlayerInfo] = useState(playerInfo);

  const providerValue = useMemo<PlayerInfoContextType>(
    () => ({
      ...localPlayerInfo,
      setPlayerName: (name) => {
        setPlayerName(name);
        setPlayerInfo(playerInfo);
      },
    }),
    [localPlayerInfo]
  );

  return (
    <PlayerIdContext.Provider value={providerValue}>
      {children}
    </PlayerIdContext.Provider>
  );
};

export default PlayerIdProvider;

import React, { FC, CSSProperties } from "react";
import cx from "classnames";

type BadgeProps = {
  className?: string;
  children: Required<Parameters<FC>[0]>["children"];
  kind?: "success" | "error" | "neutral";
  size?: "s" | "m" | "l" | "xl";
  style?: CSSProperties;
};

const Badge: FC<BadgeProps> = ({
  children,
  className,
  kind = "neutral",
  size = "m",
  style,
}) => {
  return (
    <div
      style={style}
      className={cx(
        className,
        "flex justify-center items-center text-black rounded-full text-center",
        {
          "bg-gray-200": kind === "neutral",
          "bg-green-400": kind === "success",
          "bg-red-400": kind === "error",
          "h-4 w-4": size === "s",
          "h-5 w-5": size === "m",
          "h-6 w-6": size === "l",
          "h-8 w-8": size === "xl",
        }
      )}
    >
      {children}
    </div>
  );
};

export default Badge;

import React, { FC } from "react";
// import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
} from "react-router-dom";
import Link from "./display/Link";
import GamePage from "./Pages/GamePage";
import HomePage from "./Pages/HomePage";
import LobbyPage from "./Pages/LobbyPage";
import GameStateProvider from "./Providers/GameState";
import GameSessionProvider from "./Providers/GameSession";
import PlayerIdProvider from "./Providers/PlayerId";
import ScoringPage from "./Pages/ScoringPage";
import ContentLayout from "./Layout/ContentLayout";
import Footer from "./Layout/Footer";
import FullGameStateProvider from "./Providers/FullGameState";
import ScoringStateProvider from "./Providers/ScoringState";
import Page from "./Layout/Page";

const RoutePage: FC<RouteProps & { className?: string }> = ({
  children,
  className,
  ...props
}) => (
  <Route {...props}>
    <Page className={className}>{children}</Page>
  </Route>
);

function App() {
  return (
    <Router>
      <PlayerIdProvider>
        <div className="flex flex-col h-full">
          <header className="p-4 bg-gray-800">
            <Link kind="light" to="/">
              Home
            </Link>
          </header>
          <main
            className="flex-grow md:p-8 mx-auto w-full p-4"
            style={{ maxWidth: 1250 }}
          >
            <Switch>
              <RoutePage path="/" exact>
                <HomePage />
              </RoutePage>
              <FullGameStateProvider>
                <GameStateProvider>
                  <GameSessionProvider>
                    <ScoringStateProvider>
                      <ContentLayout>
                        <RoutePage path="/lobbies/:gameId" exact>
                          <LobbyPage />
                        </RoutePage>
                        <RoutePage path="/games/:gameId" exact>
                          <GamePage />
                        </RoutePage>
                        <RoutePage
                          path="/games/:gameId/scoring/:roundIndex"
                          exact
                        >
                          <ScoringPage />
                        </RoutePage>
                      </ContentLayout>
                    </ScoringStateProvider>
                  </GameSessionProvider>
                </GameStateProvider>
              </FullGameStateProvider>
            </Switch>
          </main>
          <Footer />
        </div>
      </PlayerIdProvider>
    </Router>
  );
}

export default App;

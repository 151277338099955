import React, { createContext, FC, useMemo, useState } from "react";
import { ClientGameSession } from "../../../shared/types";

type GameStateContext = {
  gameState: Partial<ClientGameSession>;
  setGameState: (gameState: Partial<ClientGameSession>) => void;
  resetGameState: () => void;
};

export const GameStateContext = createContext<GameStateContext>({
  gameState: {},
  setGameState: () => {},
  resetGameState: () => {},
});

export const GameStateProvider: FC = ({ children }) => {
  const [gameState, setGameState] = useState<Partial<ClientGameSession>>({});
  const providerValue = useMemo(
    () => ({
      gameState,
      setGameState,
      resetGameState: () => {
        setGameState({});
      },
    }),
    [gameState]
  );

  return (
    <GameStateContext.Provider value={providerValue}>
      {children}
    </GameStateContext.Provider>
  );
};

export default GameStateProvider;

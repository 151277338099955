import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { makeId } from "../../../shared/id";
import Button from "../display/Button";
import Heading from "../display/Heading";
import IconTelephoneGame from "../display/Icon/IconTelephoneGame";
import IconPlus from "../display/Icon/IconPlus";
import IconPainting from "../display/Icon/IconPainting";
import TextIconColumn from "../display/TextIconPillar";

const HomePage: FC = () => {
  const history = useHistory();

  return (
    <div className="w-full flex flex-col items-center">
      <Heading className="self-start" level="1" size="l">
        Pictophone
      </Heading>
      <Button
        className="mt-4 md:mt-16"
        kind="primary"
        size="xl"
        onClick={() => history.push(`/lobbies/${makeId()}`)}
      >
        Create a private game
      </Button>
      <div className="flex flex-col items-center mt-32 text-gray-800">
        <Heading className="italic mb-8 text-center" level="2" size="xl">
          What is Pictophone?
        </Heading>
        <div className="flex items-center justify-center w-full mb-8 md:mb-16">
          <IconTelephoneGame size="xl" />
          <IconPlus className="ml-8" size="l" />
          <IconPainting className="ml-8" size="xl" />
        </div>
        <p className="flex flex-wrap items-start justify-center">
          <TextIconColumn
            className="max-w-sm mt-4 md:mt-16"
            heading="1. Choose a word"
          >
            If you've got an even number of players, you're going to have to
            draw that word, so keep that in mind!
          </TextIconColumn>
          <TextIconColumn
            className="max-w-sm mt-4 md:mt-16"
            heading="2. Draw the word shown"
          >
            If it's an odd number of players, it won't be the word you chose;
            you'll be drawing your neighbor's word. Don't just draw the word you
            picked in the first step!
          </TextIconColumn>
          <TextIconColumn
            className="max-w-sm mt-4 md:mt-16"
            heading="3. Guess your neighbor's drawing"
          >
            Try to guess as close to what you think the word is as possible.
            It's important for scoring!
          </TextIconColumn>
          <TextIconColumn
            className="max-w-sm mt-4 md:mt-16"
            heading="4. Repeat 2 & 3"
          >
            You'll keep alternating between drawing and guessing until the word
            you chose in step 1 comes back to you. Then scoring will start!
          </TextIconColumn>
          <TextIconColumn
            className="max-w-sm mt-4 md:mt-16"
            heading="5. Vote for points"
          >
            Once everyone is done drawing and guessing, each player's word will
            be revealed. In pairs, you will see a drawing and a guess. If the
            word guessed matches the word drawn, give them points. You can give
            them if you want to anyways!
          </TextIconColumn>
        </p>

        {/* <p>
          1. Get a group of friends, the bigger the better. While you can play with 2-3 people, it won't be nearly as fun!
          2. Start a private lobby. Share the link with your friends, and join the game.
          3. Once everyone's in, start the game!
        </p> */}
      </div>
    </div>
  );
};

export default HomePage;

import { ClientGameSession } from "../../shared/types";

export const getDidAllPlayersChooseWord = (
  gameState: Partial<ClientGameSession>
) => {
  return (
    gameState.personalRoundInfo?.didChooseWord &&
    gameState.playersRoundInfo?.every((info) => info.didChooseWord)
  );
};

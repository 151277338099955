import React, { FC } from "react";
import cx from "classnames";
import { motion } from "framer-motion";

type PageProps = {
  className?: string;
};

const Page: FC<PageProps> = ({ children, className }) => {
  return (
    <motion.div
      className={cx(className, "w-full")}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
    >
      {children}
    </motion.div>
  );
};

export default Page;

import React, { FC } from "react";
import cx from "classnames";
import { IconComponent } from "./Icon/BaseIcon";
import Heading from "./Heading";

type TextIconColumnProps = {
  heading: string;
  Icon?: IconComponent;
  className?: string;
};

const TextIconColumn: FC<TextIconColumnProps> = ({
  heading,
  Icon,
  children,
  className,
}) => {
  return (
    <div className={cx(className, "flex flex-col items-center")}>
      {Icon && <Icon className="mb-4" size="l" />}
      <Heading
        className={cx({ "mb-4 md:mb-8": children }, "text-center h-full")}
        level="3"
        size="l"
      >
        {heading}
      </Heading>
      {children && <i className="mt-auto text-center">{children}</i>}
    </div>
  );
};

export default TextIconColumn;

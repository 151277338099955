import { Player } from "./player";
import { PointAward } from "./pointAward";

export interface Entry {
  ownerId: string;
}

export interface Drawing extends Entry {
  id: string;
}

export interface Guess extends Entry {
  word: string;
}

export class Round {
  word: string;
  entries: (Drawing | Guess)[];

  constructor(word: string) {
    this.word = word;
    this.entries = [];
  }
}

export type PlayerSession = {
  // TODO: is this even needed? can be calculated
  // points: number;
  rounds: Round[];
};

export type GameSession = {
  scoringRound?: number;
  isGameActive: boolean;
  playerIds?: string[];
  currentTurnIndex: number;
  currentRoundIndex: number;
  playerInfoMap?: {
    [playerId: string]: Player;
  };
  playerRoundsMap?: {
    // TODO: or undefined?
    [playerId: string]: Round[];
  };
  playerPointsMap?: {
    [playerId: string]: PointAward[];
  };
  playerLikesMap?: {
    [playerId: string]: PointAward[];
  };
  id: string;
};

export enum PlayerAction {
  Drawing = "DRAWING",
  GuessingWord = "GUESSING_WORD",
}

export interface SafeRoundInfo {
  didChooseWord: boolean;
  isCurrentActionComplete: boolean;
  playerId: string;
}

export interface PersonalRoundInfo extends SafeRoundInfo {
  chosenWord: string;
  currentDrawingOrWord: string;
  // TODO: not sure if this is needed
  currentGuessOrDrawing?: string;
}

export interface ClientGameSession
  extends Pick<GameSession, "isGameActive" | "id"> {
  players: Player[];
  playerId: string;
  shouldDrawFirstWord: boolean;
  currentAction: PlayerAction;
  currentRoundIndex: number;
  currentTurnIndex: number;
  playersRoundInfo: SafeRoundInfo[];
  personalRoundInfo?: PersonalRoundInfo;
}

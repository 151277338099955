import React, { forwardRef, ComponentPropsWithoutRef } from "react";
import cx from "classnames";

interface InputProps extends ComponentPropsWithoutRef<"input"> {
  label: string;
  labelPos?: "top" | "left";
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, label, labelPos = "top", ...props }, ref) => {
    return (
      <label className={className}>
        <span
          className={cx(
            { block: labelPos === "top", inline: labelPos === "left" },
            "font-medium"
          )}
        >
          {label}{" "}
        </span>
        <input
          className={cx(
            {
              "bg-gray-300": props.disabled,
            },
            "p-2 border-black border-2 rounded border-solid focus:border-green-500 focus:bg-gray-100 w-full"
          )}
          ref={ref}
          {...props}
        />
      </label>
    );
  }
);

export default Input;
